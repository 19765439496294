import React, { FC, RefObject, useState, MouseEvent, useRef, useEffect } from 'react';
import { Grid, Divider, AlertColor, CircularProgress, useTheme, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Form, { FormDataModel } from '../../../components/Form';
import { removeErrorFieldsFromValues } from '../../../utils/validators';
import Notification from '../../../components/Notification';
import axiosInstance from '../../../utils/axios';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import Navigation from '../../../utils/hooks/Navigation';
import strings from 'common/Translation/Translate';
import { ScrollTop, fetchErrorFields } from 'utils/validators/HelperFunctions';
import { identifiers } from 'utils/constants/identifiers';
import { fetchFromStorage } from 'utils/storage';
import { routes } from 'utils/constants/routes';
interface AddEditProps {
  pageConfig: any;
  pageResponse: object;
  handlePropsChange?: (key: string, value: any, formdata: any, deleted: any) => void;
  handlePropsFocus?: (key: string, value: any, formdata: any, deleted: any) => void;
  handleCustomResponse?: (body: FormDataModel, preview?: string) => any;
  customCallbackResponse?: any;
  customButtonClick?: any;
  handleSecondaryButton?: any;
  isFormUpdated?: boolean;
  dropdownValue?: any;
  isFormData?: any;
  children?: any;
  id: any,
  getList?: any,
  handleDrawerClose?: any
}
const AddEdit: FC<AddEditProps> = ({
  pageConfig,
  pageResponse,
  handlePropsChange,
  handleCustomResponse,
  customCallbackResponse,
  customButtonClick,
  dropdownValue,
  handleSecondaryButton,
  handlePropsFocus,
  children,
  id,
  getList,
  handleDrawerClose
}) => {
  const Navigate = useNavigate();
  const theme = useTheme();
  // const { id } = useParams() as any;
  let formRef: RefObject<Form | null | undefined> = useRef();
  const org = fetchFromStorage(identifiers.organization) as any;
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: any;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  function handleCatch(error: any) {
    setLoading(false);
    if (error.response) {
      setMessage({
        display: true,
        severity: 'warning',
        message: error.response.data.message,
      });
    } else {
      setMessage({
        display: true,
        severity: 'warning',
        message: error.message,
      });
    }
  }

  useEffect(() => {
    if (customButtonClick > 0) {
      //console.log(customButtonClick);
      handleAddEdit('preview');
    }
  }, [customButtonClick]);
  function onSuccess(data: any) {
    setLoading(false);
    setMessage({
      display: true,
      severity: 'success',
      message: pageConfig.successMessageWithLink ? pageConfig.successMessage(data) : pageConfig.successMessage,
    });
      setTimeout(() => {
        if(handleDrawerClose) {
          handleDrawerClose();
          getList && getList()
        }
        if (customCallbackResponse) {
          customCallbackResponse(data);
        }
        if (pageConfig.navigateToPage) {
          Navigate(`${pageConfig.navigateToPage}`);
        }
      }, 1000);
      
  }
  const handleAddEdit = async (e?: MouseEvent<HTMLButtonElement> | string) => {
    try {
      // debugger;
      const { getFormData } = formRef.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData, isFormValid } = getFormData();
      const body = removeErrorFieldsFromValues(formData);
      const method = id || pageConfig?.queryStingId ? 'put' : 'post';
      const customMethodHelper = pageConfig.customMethod?.toLowerCase() === 'put' ? 'put' : 'post';
      const customMethod = pageConfig.customMethod ? customMethodHelper : method;
      ScrollTop(0);
      setHasError(false);
      if (isFormValid) {
        //console.log('body: ', body);
        const requestData = handleCustomResponse ? handleCustomResponse(body, e as any) : body;
        if (requestData === (false as any)) {
          return false;
        }
        setLoading(true);
        const { status, data } = await axiosInstance[customMethod](
          pageConfig.addEditApiUrl,
          requestData,
          pageConfig.isFormData && {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        if (status === 200 || status === 201) {
          // formRef.current?.resetForm();
          onSuccess(data);
        }
      } else {
        setMessage({
          display: true,
          severity: 'error',
          message: `${strings.required_error_prefix_message} ${fetchErrorFields(formData, pageConfig.formModel).join(
            ', ',
          )}`,
        });
        setHasError(true);
      }
    } catch (error: any) {
      handleCatch(error);
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };
  const handleChange = (key: string, value: any, formdata: any, delted: any) => {
    if (handlePropsChange) {
      handlePropsChange(key, value, formdata, delted);
    }
  };

  const handleFocus = (key: string, value: any, formdata: any, delted: any) => {
    if (handlePropsFocus) {
      handlePropsFocus(key, value, formdata, delted);
    }
  };
  return (
    <Grid className="content">
      {/* <Grid container spacing={2}> */}
        {message.display && (
          <Notification
            isOpen={message.display}
            message={message.message}
            severity={message.severity as AlertColor}
            closeNotification={closeNotification}
          />
        )}
        {/* {!pageConfig.hideClose && (
          <>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: 24, fontWeight: 500 }}> {pageConfig.title}</Typography>
            </Grid>
            {org !=='*' && <Grid item textAlign="right" xs={2}>
              <Navigation url={pageConfig.navigateToPage || -1} />
            </Grid>}
          </>
        )}
      </Grid>
      {!pageConfig.hideClose && <Divider sx={{ my: 2 }} />} */}
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Form
          isFormUpdated={id}
          dropdownData={dropdownValue ? dropdownValue : {}}
          hasError={hasError}
          ref={formRef as RefObject<Form>}
          model={pageConfig.formModel}
          values={pageResponse}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </Grid>
      {children}
      <Grid container sx={{ my: 5 }} gap={1}>
        <Grid item xs={12} sm={3} md={2} lg={1}>
          <PrimaryButton sx={{ minWidth: '100px' }}
            disabled={loading}
            variant="contained" onClick={handleAddEdit}>
            {loading && <CircularProgress color="inherit" sx={{ mr: 1 }} size={20} />}
            {id ? strings.common_button_save : strings.common_column_add}
          </PrimaryButton></Grid>
        {pageConfig.secondaryButtonText && id && (
          <Grid item xs={12} sm={3} md={2} lg={1}>
            <PrimaryButton
              disabled={!id}
              sx={{
                '&.defaultButton': { background: theme.palette.primary.main, color: theme.palette.primary.contrastText },

              }}
              variant="contained"
              onClick={() => handleSecondaryButton(id)}>
              {loading && <CircularProgress color="inherit" sx={{ ml: 1 }} size={20} />}
              {pageConfig.secondaryButtonText}
            </PrimaryButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default AddEdit;
