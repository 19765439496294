import React, { useState, FC, MouseEvent, ChangeEvent } from 'react';
import './input.css';
import { validateInput } from '../../utils/validators/Validators';
import { TextField, IconButton, TextFieldProps } from '@mui/material';
import { InfoOutlined, Visibility, VisibilityOff } from '@mui/icons-material';

import { FormValidators } from '../Form';
import { ToolTip } from 'components/Tooltip/ToolTip';
import strings from 'common/Translation/Translate';

interface OwnProps {
  leftIcon?: string;
  inputValue?: string;
  field?: string;
  typeValue?: string;
  textChange?: (value: string, field: string, error?: { error: boolean; message: string }) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: boolean | string;
  multiline?: boolean;
  rows?: number | string;
  minRows?: number | string;
  maxRows?: number | string;
  infoText?: any;
}

const InputField: FC<OwnProps & TextFieldProps> = ({
  hasError,
  validators,
  textChange,
  field,
  inputValue,
  placeholder,
  fieldError,
  inputProps,
  InputProps,
  variant,
  disabled,
  label,
  size = 'medium',
  typeValue,
  style,
  autoFocus,
  sx,
  className,
  multiline = false,
  rows = 0,
  minRows = 1,
  maxRows = 1,
  infoText = null,
  ...rest

}) => {
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const [pass, setPass] = useState(false);

  const handleChange = (value: string) => {
    const inputError = validateInput(validators as FormValidators[], value);
    setError(inputError);
    if (textChange) {
      textChange(value, field as string, inputError);
    }
  };
  const handleTogglePassword = () => {
    setPass(!pass);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const customMessage = () => {
    if (!error && hasError && fieldError) {
      return strings.requiredText
    }
    else return '';
  }

  const endIcon = () => {
    let returnValue;
    if (typeValue === 'password') {
      returnValue = 
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleTogglePassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {pass ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    }
    if (infoText) {
      returnValue = <ToolTip title={infoText}>
        <IconButton
          aria-label="infoText"
          color='primary'
          onClick={() => { }}
          edge="end">
          <InfoOutlined />
        </IconButton>
      </ToolTip>
    }
      return returnValue;
  }
  return (
    <TextField
      autoFocus={autoFocus}
      sx={sx}
      size={size}
      autoComplete="off"
      InputProps={{
            ...InputProps,
            endAdornment: endIcon()
          }}
      inputProps={inputProps}
      style={style}
      disabled={disabled}
      type={pass ? 'text' : typeValue}
      helperText={error && error.error ? error.message : customMessage()}
      error={(error && error.error) || (!error && hasError && fieldError) ? true : false}
      color="primary"
      fullWidth
      className={`input-field ${fieldError}`}
      value={inputValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) => (textChange ? handleChange(e.target.value) : e)}
      placeholder={placeholder}
      label={label}
      variant={variant}
      {...rest}
      multiline={multiline}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
    />
  );
};
export default InputField;
