import PageLoader from 'components/PageLoader';
import { useCallback, useEffect, useState } from 'react';
import strings from '../../../../common/Translation/Translate';
import axiosInstance from '../../../../utils/axios';
import { URLS } from '../../../../utils/constants/urls';
import AddEdit from '../../CommonPage/AddEdit';
import { AddProductsForm } from './AddEditProductForm';

const AddEditProducts = ({ id, handleDrawerClose, getList }: { id: any; handleDrawerClose: any; getList: any }) => {
  //   const { id } = useParams() as any;
  const [pageResponse, setPageResponse] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getInitialResponse = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.productListById(id));
      const fileData = data.image;
      const newFiles = pageResponse.addOnImage ? pageResponse?.addOnImage : [];
      if (fileData) {
        const objFile = {} as any;
        objFile.preview = fileData ? fileData : null;
        objFile.name = data.fileName;
        objFile.id = data.id;
        newFiles.push(objFile);
      }
      const unique = newFiles?.filter((obj: any, index: number) => {
        return index === newFiles.findIndex((o: any) => obj.name === o.name);
      });
      setPageResponse({
        ...data,
        image: unique,
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getInitialResponse();
      }
    })();
  }, [getInitialResponse, id]);

  const pageConfig = {
    title: id ? 'Edit Product' : 'Add Product',
    successMessage: id ? strings.common_update_message : strings.common_save_message,
    addEditApiUrl: id ? URLS.productListById(id) : URLS.productAdd,
    initialApi: URLS.productListById(id),
    formModel: AddProductsForm(strings),
    isCustomResponse: true,
    isFormData: true,
  };

  const handleCustomResponse = (custom: any) => {
    //console.log('custom: ', custom)
    if (!custom.image[0]?.lastModified) {
      return { ...custom, image: null };
    }
    return { ...custom, image: custom.image[0] };
  };

  return (
    <>
      {loading && <PageLoader />}
      <AddEdit
        pageConfig={pageConfig}
        pageResponse={pageResponse}
        id={id}
        handleCustomResponse={handleCustomResponse}
        handleDrawerClose={handleDrawerClose}
        getList={getList}
      />
    </>
  );
};

export default AddEditProducts;
