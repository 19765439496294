import { AlertColor } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import strings from '../../../common/Translation/Translate';
import Notification from '../../../components/Notification';
import PageLoader from '../../../components/PageLoader';
import axiosInstance from '../../../utils/axios';
import { images } from '../../../utils/constants/images';
import { privileges } from '../../../utils/constants/privileges';
import { routes } from '../../../utils/constants/routes';
import { URLS } from '../../../utils/constants/urls';
import ListPage from '../CommonPage';
import { UserFilter } from './UserFilter';

const UserManagement: FC = () => {
  const [pageResponse, setPageResponse] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  const handleCustomImageMethod = async (id: number) => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.put(URLS.resetUser(id));
      if (status === 200) {
        setLoading(false);
        setMessage({
          display: true,
          severity: 'success',
          message: strings.password_reset_success_message,
        });
      }
    } catch (error: any) {
      setLoading(false);
    }
  };
  const toggleUser = async (id: number) => {
    try {
      const { status } = await axiosInstance.put(URLS.toggleUser(id));
      if (status === 200) {
        setMessage({
          display: true,
          severity: 'success',
          message: strings.status_change_success_message,
        });
        getList(null);
      }
    } catch (error: any) { }
  };
  const pageConfig = {
    title: 'User Management',
    AddButtonText: 'Add User',
    AddButtonUrl: routes.addUser,
    editButtonUrl: routes.users,
    listApi: URLS.users(false),
    deleteApi: URLS.deleteUserById,
    addPrivilege: privileges.add_user,
    editPrivilege: privileges.edit_user,
    deletePrivilege: privileges.delete_user,
    blockUserPrivilege: privileges.block_user,
    resetPasswordPrivilege: privileges.reset_password_user,
    customImage: {
      imageUrl: images.resetPasswordIcon,
      handleCustomImageMethod,
      tooltip: strings.reset_password_title,
      privilege: privileges.reset_password_user,
    },
    toggleUser,
    filterForm: UserFilter(strings),
    tableColumn: [
      { field: 'firstName', headerName: 'First Name', flex: 1, sortable: true },
      { field: 'lastName', headerName: 'Last Name', flex: 1, sortable: true },
      { field: 'email', headerName: 'Email Id', flex: 1, sortable: true },
      { field: 'phone', headerName: 'Phone no', flex: 1, sortable: false },
    ],
    isActionCol: true
  };
  const getList = useCallback(
    async (filterData: any) => {
      try {
        setLoading(true);
        const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
        const { data } = await axiosInstance.post(pageConfig.listApi, body);
        setPageResponse(data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [pageConfig.listApi],
  );

  useEffect(() => {
    (async () => {
      await getList(null);
    })();
  }, [getList]);
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };
  return (
    <>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <ListPage pageConfig={pageConfig} pageResponse={pageResponse} getList={getList} />
    </>
  );
};
export default UserManagement;
