import strings from 'common/Translation/Translate';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddUserForm } from './AddUserForm';
import { getRoles } from './userService';
import axiosInstance from 'utils/axios';
import { URLS } from 'utils/constants/urls';
import AddEdit from 'screens/Authenticated/CommonPage/AddEdit';
import { routes } from 'utils/constants/routes';


const AddEditUser = () => {
  const { id } = useParams() as any;
  const [roleList, setRoleList] = useState<any>([]);
  const [pageResponse, setPageResponse] = useState<any>();
  const [language, setLanguage] = useState<any>([]);
  const [country, setCountry] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);

  const getUser = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.getUserById(id));
      //console.log('pageResponse-data: ', data)
      setPageResponse({...data, roles: data.roles[0].value});
    } catch (error: any) {}
  }, [id]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getUser();
      } else {
        setPageResponse({ ...pageResponse });
      }
    })();
  }, [getUser, id]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getRoles();
        if (response) {
          let roles = response.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          setRoleList(roles);
        }
      } catch (e) {}
    })();
  }, []);

 
  const pageConfig = {
    title: id ? 'Edit User' : 'Add User',
    successMessage: id ? strings.common_update_message : strings.common_save_message,
    addEditApiUrl: id ? URLS.editUserById(id) : URLS.addUser,
    initialApi: URLS.getUserById(id),
    formModel: AddUserForm(roleList),
    navigateToPage: routes.users,
    isCustomResponse: true,
  };

  const handleCustomResponse = (custom: any) => {
    if (custom) {
      //console.log('role: ', custom.roles);
      return {
        ...custom,
        id: pageResponse.id,
        roles: [custom.roles],
      };
    }
  };

  //console.log('pageResponse: ', pageResponse)

  return (
    <AddEdit  
      id={id}    
      pageConfig={pageConfig}
      pageResponse={pageResponse}
      handleCustomResponse={handleCustomResponse}
    />
  );
};
export default AddEditUser;
