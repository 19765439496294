import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { fetchFromStorage, removeFromStorage, saveToStorage } from '../storage';
import { identifiers } from '../constants/identifiers';
import Config from '../Config';
import { URLS } from '../constants/urls';
import { logoutUser } from '../validators/HelperFunctions';

const axiosInstance = Axios.create({
  baseURL: Config.BaseURL,
  headers: { 'Content-Type': 'application/json' },
  // params: { lang: 'en_IN' },
});
axiosInstance.defaults.timeout = 300000;

const refreshToken = () => {
  const body = new URLSearchParams();
  const token = fetchFromStorage(identifiers.refresh_token);

  body.append('refresh_token', token || '');
  body.append('scope', 'webclient');
  body.append('grant_type', 'refresh_token');
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    auth: {
      username: Config.REACT_APP_CLIENT,
      password: Config.REACT_APP_SECRET,
    },
  };

  Axios.post(Config.BaseURL + URLS.login, body, config as any)
    .then(({ data, status }) => {
      if (status === 200) {
        removeFromStorage(identifiers.token);
        saveToStorage(identifiers.access_token, data.access_token);
        saveToStorage(identifiers.privilegeList, data.privileges);
        saveToStorage(identifiers.refresh_token, data.refresh_token);
        window.location.reload();
      }
    })
    .catch((error: any) => {
      //console.log(error);
      logoutUser();
    });
};

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = fetchFromStorage(identifiers.access_token);
  const lang = JSON.parse(fetchFromStorage(identifiers.selectedLanguage) as any)
  const clonedConfig: any = config;

  if (token) {
    clonedConfig.headers.common = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  clonedConfig.params = {
    lang: lang ? lang.value : 'en_IN',
    ...config.params,
  };
  //console.log('clonedConfig: ',lang, clonedConfig)
  return clonedConfig;
});
// const MAX_RETRIES = 1;
// let retries = 0;
axiosInstance.interceptors.response.use(
  (config: AxiosResponse) => {
    return config;
  },
  (error: AxiosError) => {
    //console.log('error: ',error);
    if (error.response && error.response.status === 401 && error.response.config.url !== URLS.login) {
      refreshToken();
    } else if (error.response && (error.code === 'ERR_NETWORK' || error.response.status === 503)) {
      window.location.href = '#/errorPage';
    } else {
      return Promise.reject(error);
    }
  },
);

export default axiosInstance;
