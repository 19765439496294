import { Fragment, useState, useCallback, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { FormValidators } from 'components/Form';
import { validateInput } from 'utils/validators/Validators';
import axiosInstance from 'utils/axios';
import Debounce from 'utils/validators/debounce';
import strings from 'common/Translation/Translate';

interface OwnProps {
  url: any;
  field: string;
  disabled?: boolean;
  placeholder?: string;
  typeValue?: string;
  onChange: (value: string, field: string, error?: { error: boolean; message: string }) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: string | boolean;
  label?: string;
  size?: any;
  sx?: any;
  id?: any;
  value: any;
}

const AutocompleteDropdown = ({
  url,
  field,
  disabled,
  placeholder,
  onChange,
  validators,
  hasError,
  fieldError,
  label,
  size,
  sx,
  id,
  value,
}: OwnProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<{
    error?: boolean;
    message?: string;
  } | null>({ error: hasError, message: fieldError as string });

  useEffect(() => {
    if (options.length < 1) {
      setOpen(false);
    }
  }, [options]);
  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      const val = typeof(newValue) === 'object' ? newValue[field] : newValue
      const inputError = validateInput(validators as FormValidators[], val);
      setError(inputError);
      onChange(newValue, field, inputError);
    }
  };
  const handleInputChange = (event: any, newInputValue: string | null) => {
    let newValue = '';
    if (newInputValue !== undefined && newInputValue !== null && event !== null) {
      const inputError = validateInput(validators as FormValidators[], newInputValue);
      setError(inputError);
      if (newInputValue.includes('-')) {
        newValue = newInputValue?.trim()?.split('-')[0];
      } else {
        newValue = newInputValue?.trim();
      }
      setInputValue(newValue);
      onChange(newValue, field, inputError);
    }
  };
  const customMessage = () => (!error && hasError && fieldError ? strings.requiredText : '');
  const getOptions = useCallback(
    async (val: string) => {
      try {
        if (val !== '') {
          setLoading(true);
          const { data } = await axiosInstance.get(url(val));
          setLoading(false);
          setOptions(data);
        } else {
          setOptions([]);
        }
      } catch (error: any) {
        setLoading(false);
      }
    },
    [url],
  );

  const optimizedFn = useCallback(
    Debounce((val: string) => getOptions(val), 1000),
    [],
  ) as any;

  useEffect(() => {
    let val = inputValue !== '' ? inputValue : value;
    optimizedFn(val);
  }, [optimizedFn, inputValue, value]);

  return (
    <Autocomplete
      id={id}
      clearOnBlur={false}
      sx={{ ...sx }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      inputValue={inputValue || value}
      placeholder={placeholder}
      disabled={disabled}
      isOptionEqualToValue={(option: any, value: any) => option.firstName === value.firstName}
      getOptionLabel={(option: any) => option.firstName + '-' + option?.email || inputValue}
      options={options}
      loading={loading}
      onFocus={() => setOpen(false)}
      onChange={(event, newValue) => {
        handleChange(value, newValue);
      }}
      onInputChange={(value, newInputValue) => {
        handleInputChange(value, newInputValue);
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          size={size}
          label={label}
          helperText={error && error.error ? error.message : customMessage()}
          error={error?.error || (hasError && fieldError) ? true : false}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {/* {params.InputProps.endAdornment} */}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteDropdown;
