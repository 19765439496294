import { useState } from "react";
import "./styles.css";
import { validateInput } from "../../utils/validators/Validators";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import strings from "common/Translation/Translate";

const DropDownInput = ({
  hasError,
  validators,
  onChange,
  onFocus,
  field,
  inputValue,
  options,
  fieldError,
  inputProps,
  disabled,
  className,
  id,
  labelId,
  label,
  style,
  placeholder,
  sx,
  size,
}) => {
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    const { value } = event.target;
    const inputError = validateInput(validators, value);
    setError(inputError);
    onChange(value, field, inputError);
  };
  const customMessage = () => !error && hasError && fieldError ? strings.requiredText : ""
  return (
    <FormControl
      size={size}
      error={!!((error && error.error) || (!error && hasError && fieldError))}
      className={`dropdown ${className}`}
      fullWidth
    >
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        sx={sx}
        style={style}
        inputProps={inputProps}
        disabled={disabled}
        labelId={labelId}
        id={id}
        value={inputValue}
        label={label}
        placeholder={placeholder}
        onChange={(value) => {
          handleChange(value);
        }}
        
        onOpen={(value) => {
          onFocus(value, field);
        }}
      >
        {options.map((item, i) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {error && error.error ? error.message : customMessage()}
      </FormHelperText>
    </FormControl>
  );
};
export default DropDownInput;
