import { FC, useEffect, useState, useCallback } from 'react';
import {
  AlertColor,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PageLoader from '../../../components/PageLoader';
import { useSelector } from 'react-redux';
import { Privilege } from '../../../utils/redux/reducer/authentication-slice';
import strings from 'common/Translation/Translate';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/axios';
import ListPage from '../CommonPage';
import { privileges } from 'utils/constants/privileges';
import axios from 'axios';
import Config from 'utils/Config';
import { fetchFromStorage } from 'utils/storage';
import { downloadExportFile } from 'utils/validators/HelperFunctions';
import { identifiers } from 'utils/constants/identifiers';
import Notification from 'components/Notification';
import { OrdersFilterForm } from './OrdersFilterForm';

const Orders: FC = () => {
  const theme = useTheme();
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const screenSizeUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const token = fetchFromStorage(identifiers.access_token) as any;
  const [pageResponse, setPageResponse] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      axios({
        url: `${Config.BaseURL + URLS.downloadOrders}`,
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })
        .then(response => {
          setLoading(false);
          downloadExportFile(response, 'csv', `Orders`);
        })
        .catch(err => {
          setLoading(false);
          setMessage({
            display: true,
            severity: 'error',
            message: strings.something_went_wrong,
          });
        });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data.message,
      });
    }
  }

  const pageConfig = {
    title: strings.menu_text_orders,
    listApi: URLS.orderList,
    downloadPrivilege: privileges.export_order,
    downloadButtonText: strings.DownloadOrders,
    downloadFunc: handleDownload,
    tableColumn: [
      { field: 'name', headerName: 'Name', flex: 1, sortable: true },
      { field: 'email', headerName: 'Email', flex: 1, sortable: true },
      { field: 'phone', headerName: 'Phone', flex: 1 },      
      { field: 'orderId', headerName: 'OrderId', flex: 1, sortable: true },
      { field: 'orderDateString', headerName: 'Order date', flex: 1, sortable: true },
      { field: 'orderStatus', headerName: 'Order Status', flex: 1 },
      { field: 'amount', headerName: 'Amount', flex: 1 },
      { field: 'productCode', headerName: 'Product Code', flex: 1, sortable: true },
      { field: 'variantCode', headerName: 'Variant Code', flex: 1 },
      { field: 'couponCode', headerName: 'Coupon Code', flex: 1, sortable: true },
      { field: 'loyaltyPoints', headerName: 'Loyalty Points', flex: 1 },
      { field: 'quantity', headerName: 'Quantity', flex: 1, sortable: true },
    ],
    isActionCol: false,
    filterForm: OrdersFilterForm(strings, 12),
  };

  const getList = useCallback(
    async (filterData: any) => {
      try {
        setLoading(true);
        const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
        const { data } = await axiosInstance.post(pageConfig.listApi, body);
        const customContent = data.content.map((item: any) => ({
          email: item.customerOrder.email,
          phone: item.customerOrder.phone,
          orderDateString: item.customerOrder.orderDateString,
          orderId: item.customerOrder.orderId,
          orderStatus: item.customerOrder.orderStatus,
          amount: item.customerOrder.amount,
          productCode: item.productCode,
          variantCode: item.variantCode,
          couponCode: item.couponCode,
          loyaltyPoints: item.loyaltyPoints,
          quantity: item.quantity,
          name: `${item.customerOrder.firstName} ${item.customerOrder.lastName}`
        }))
        const customData = { ...data, content: customContent } 
        setPageResponse(customData);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [pageConfig.listApi],
  );

  useEffect(() => {
    (async () => {
      await getList(null);
    })();
  }, [getList]);

  return (
    <>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <ListPage loading={loading} pageConfig={pageConfig} pageResponse={pageResponse} getList={getList} />
    </>)
};
export default Orders;
