export const identifiers: { [key: string]: string } = {
  access_token: 'access_token',
  preview: 'previewLink',
  translation: 'translation',
  languages: 'languages',
  privilegeList: 'privilege_list',
  refresh_token: 'refresh_token',
  date_format: 'DD/MM/YYYY',
  date_input: 'YYYY-MM-DD',
  month_input: 'MMM-YYYY',
  field_error: 'This Field is Required',
  filters: 'filters',
  selectedLanguage: 'selectedLanguage',
  theme: 'theme',
  logo: 'https://uploads-ssl.webflow.com/5e46403ef1271f0d2212e4d5/5e468b39fcbf69f9b697bfea_192-150x150.png',
  REACT_APP_CAPTCHA: '6LdW4MEUAAAAAFOkFLXkwcp1bmG7yo-0uF-RNVjH',
  currency: 'currency',
  organization: 'organization',
};
