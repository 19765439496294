import { Validators } from '../../../utils/validators';
import { FormModel } from '../../../components/Form';

export const LoginForm = (strings: any): FormModel[] => {
  return [
    {
      label: strings.emailText,
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'username',
      validators: [{ check: Validators.validateMandatoryField }, { check: Validators.validateEmail }],
      responsive: { xs: 12 },
      required: true,
    },
    {
      label: strings.PasswordText,
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'password',
      variant: 'outlined',
      placeholder: '',
      field: 'password',
      sx: { mt: 2, mb: 5 },
      validators: [{ check: Validators.validateMandatoryField }],
      responsive: { xs: 12 },
      required: true,
    },
  ];
};
