import { createTheme, Grid, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import Routes from './routes';

function App() {
  const themes = {
    primary: '#1890FF',
    primaryTextColor: '#FFFFFF',
    secondary: '#001529',
    secondaryTextColor: '#FFFFFF',
  };
  const selectedlangLocaleCode = 'en';
  const theme = createTheme({
    // direction: 'rtl',
    palette: {
      mode: 'light' || 'dark',
      primary: {
        main: themes.primary,
        contrastText: themes.primaryTextColor,
      },
      secondary: {
        main: themes.secondary,
        contrastText: themes.secondaryTextColor,
      },
    },
    typography: {
      fontFamily: ['Roboto'].join(','),
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            boxShadow: '0px 4px 62px rgba(0, 0, 0, 0.08)',
          },
        },
      },
      // MuiButton: {
      //   styleOverrides: {
      //     contained: {
      //       backgroundImage: themes?.buttonGradient
      //     },
      //   },
      // },
      MuiAccordion: {
        styleOverrides: {
          root: {
            ':last-of-type': {
              borderRadius: 0,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            width: '100vw',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '0.75rem',
            marginLeft: 0,
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={selectedlangLocaleCode}>
        <Grid container className="main-body">
          <Routes />
        </Grid>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
