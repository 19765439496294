import React, { FC, useState, useEffect, useCallback, } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../../../utils/constants/routes";
import axiosInstance from "../../../../../utils/axios";
import AddEdit from "../../../CommonPage/AddEdit";
import { AddRoleForm } from "./AddRoleForm";
import { URLS } from "../../../../../utils/constants/urls";
import strings from "../../../../../common/Translation/Translate";

const AddEditRole: FC = () => {
  const { id } = useParams() as any;
  const Navigate = useNavigate();
  const [pageResponse, setPageResponse] = useState<any>([]);

  const getInitialResponse = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.editRole(id));
      setPageResponse(data);
    } catch (error: any) {
    }
  },
    [id]
  );

  useEffect(() => {
    (async () => {
      if (id) {
        await getInitialResponse();
      }
    })();
  }, [getInitialResponse, id]);


  const customCallbackResponse = (callbackValue: any) => {
    Navigate(routes.privilige + '/' + callbackValue.id)
}

  const pageConfig =
  {
    title: id ? strings.rolesmgmt_title_edit : strings.roles_title_add,
    successMessage: id ? strings.common_update_message : strings.common_save_message,
    addEditApiUrl: id ? URLS.editRole(id) : URLS.addRole,
    initialApi: URLS.editRole(id),
    formModel: AddRoleForm(strings, 4),
    navigateToPage: null,
    isCustomResponse: false,
  }

  return (<AddEdit id={id} pageConfig={pageConfig} pageResponse={pageResponse} customCallbackResponse={customCallbackResponse}/>);
};
export default AddEditRole;