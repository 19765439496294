export const privileges = {

  view_role: 'VIEW_ROLE',
  add_role: 'ADD_ROLE',
  edit_role: 'EDIT_ROLE',
  delete_role: 'DELETE_ROLE',
  edit_privileges: 'EDIT_PRIVILEGES',

  view_product: 'VIEW_PRODUCT',
  add_product: 'ADD_PRODUCT',
  edit_product: 'EDIT_PRODUCT',
  delete_product: 'DELETE_PRODUCT',
  import_product: 'IMPORT_PRODUCT',
  export_product: 'EXPORT_PRODUCT',
  import_variant: 'IMPORT_VARIANT',

  view_order: 'VIEW_ORDER',
  add_order: 'ADD_ORDER',
  export_order: 'EXPORT_ORDER',

  redeem_promotion: 'REDEEM_PROMOTION',
  view_coupon: 'VIEW_COUPON',
  import_coupon: 'IMPORT_COUPON',
  delete_coupon: 'DELETE_COUPON',

  view_variant: "VIEW_VARIANT",
  add_variant: "ADD_VARIANT",
  edit_variant: "EDIT_VARIANT",
  delete_variant: "DELETE_VARIANT",

  view_user: 'VIEW_USER',
  add_user: 'ADD_USER',
  edit_user: 'EDIT_USER',
  delete_user: 'DELETE_USER',
  reset_password_user: 'RESET_PASSWORD_USER',
  block_user: 'BLOCK_USER',

};
