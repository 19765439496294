import {
  AlertColor,
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LogoPrimary from 'assets/images/LogoPrimary';
import { FC, MouseEvent, RefObject, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import strings from '../../../common/Translation/Translate';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import Form, { FormDataModel } from '../../../components/Form';
import Notification from '../../../components/Notification/Notification';
import axiosInstance from '../../../utils/axios';
import { routes } from '../../../utils/constants/routes';
import { URLS } from '../../../utils/constants/urls';
import { setAuthentication } from '../../../utils/redux';
import { defaultLanguage } from '../../../utils/redux/reducer/authentication-slice';
import { removeErrorFieldsFromValues } from '../../../utils/validators';
import './Login.scss';
import { LoginForm } from './LoginForm';
import Config from 'utils/Config';
import Logo from 'assets/images/Logo';
// import cbt_logo from '../../../assets/images/cbt_logo.png';

const Login: FC<any> = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultLang = useSelector(defaultLanguage);
  // const dropdownValue = { value: 'en_IN', label: 'English' };
  const language = defaultLang;
  const theme = useTheme();
  let loginForm: RefObject<Form | null | undefined> = useRef();
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const [selectedLanguage, setSelectedLanguages] = useState<string>(language);

  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const handleLogin = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { getFormData } = loginForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const bodyData = removeErrorFieldsFromValues(formData);
    const body = new URLSearchParams();
    body.append('username', bodyData.username as string);
    body.append('password', bodyData.password as string);
    body.append('scope', 'webclient');
    body.append('grant_type', 'password');
    try {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        auth: {
          username: Config.REACT_APP_CLIENT,
          password: Config.REACT_APP_SECRET,
        },
      };
      // debugger;
      setHasError(false);
      if (isFormValid) {
        setLoading(true);
        const { status, data } = await axiosInstance.post(URLS.login, body, config as any);
        const { firstTimeLogin, organization } = data;
        // const  firstTimeLogin = true;
        // const organization = null
        if (status === 200) {
          setLoading(false);
          if (firstTimeLogin) {
            Navigate(
              `/reset-password?email=${formData.username}&refresh-token=${data.refresh_token}&access-token=${data.access_token}&firstTimeLogin=${firstTimeLogin}&orgId=${organization}`,
              { replace: true },
            );
          } else if (!firstTimeLogin) {
            dispatch(setAuthentication(data));
            Navigate(routes.product, { replace: true });
          }
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setActionMessage(true, 'warning', error.response.data.error_description);
      }
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const setActionMessage = (display: boolean = false, severity: AlertColor = 'warning', message: string = '') => {
    setMessage({
      display,
      severity,
      message,
    });
  };

  return (
    <Container sx={screenSizeUpLg ? {} : { px: 0 }} className={'login-container'} maxWidth={false}>
      <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh)' }}>
        {message.display && (
          <Notification
            isOpen={message.display}
            message={message.message}
            severity={message.severity as AlertColor}
            closeNotification={closeNotification}
          />
        )}
        <Grid item xs={12} sm={8} lg={4}>
          <Box textAlign="center" mb={3} bgcolor={theme.palette.primary.main} py={2}>
            <Logo />
          </Box>
          <Card sx={{ p: screenSizeUpLg ? 2 : 1, mx: screenSizeDownSm ? 2 : 0 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Welcome to CloverBay Labs
            </Typography>
            <form onSubmit={handleLogin as any}>
              <Form hasError={hasError} ref={loginForm as RefObject<Form>} model={LoginForm(strings)} values={{}} />

              <PrimaryButton disabled={loading} sx={{ mt: 1, p: 1.5 }} onClick={handleLogin} type="submit">
                {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                {strings.loginText}
              </PrimaryButton>
            </form>
            <Link to={'/forgot-password'} className="other-links">
              <Typography variant="body1" sx={{ mb: 1, mt: 2 }} align={'center'}>
                {strings.forgotPassword}
              </Typography>
            </Link>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
