export const en_IN = {
  login_title_text: 'Welcome to Bumblegift.com',
  loginText: 'Login',
  emailText: 'Email Id',
  PasswordText: 'Password',
  invalid_email_error: 'Email is invalid',
  loginFormError: 'Please check email or password',
  forgotPassword: 'Forgot Password',
  forgot_password_title: 'Forgot Password',
  reset_password_title: 'Reset Password',
  reset_password_successMessage: 'Password change Successfully',
  reset_password_codePlaceholder: 'Enter code',
  reset_password_resend_code_successMsg: 'Verification code sent successfully',
  reset_password_text1: 'You will receive a verification code at',
  reset_password_text2:
    'Please enter the code below to complete the verification of your email. The verification of the email is mandatory before login.',
  codeResendsuccessMessage: 'Code has been Re-sent Successfully',
  password_reset_success_message: 'Password has been reset successfully',
  status_change_success_message: 'Status has been change successfully',
  profile_reset_success_message: 'Profile has been updated Successfully',
  code_validated_success_message: 'Code validated successfully',
  tryAgainMessage: 'Please try again',
  login_failed_error: 'Login failed. Please check credentials again.',
  something_went_wrong: 'Something went wrong. Please try again.',
  password_missMatch_msg: 'password mismatch',
  clearText: 'Clear',
  clear_filter_text: 'Clear Filter',
  no_data_available: 'No data available',
  action_text: 'Actions',
  continue_text: 'Continue',
  filter_text: 'Filters',
  editText: 'Edit',
  common_button_back: 'Back',
  deleteText: 'Delete',
  confirmDeletedText: 'Are you sure! You want to Delete?',
  closeText: 'Close',
  common_button_back: 'Back',
  common_button_save: 'Save',
  common_column_add: 'Add',
  customermgmt_column_role: 'Role',
  rolesmgmt_button_add: 'New Role',
  rolesmgmt_column_edit: 'Edit',
  rolesmgmt_column_delete: 'Delete',
  rolesmgmt_column_name: 'Name',
  rolesmgmt_text_name: 'Role Name',
  rolesmgmt_column_description: 'Description',
  rolesmgmt_column_privilege: 'Privilege',
  rolesmgmt_column_privilegeName: 'Privilege Name',
  rolesmgmt_title_add: 'Add Role',
  rolesmgmt_title_edit: 'Edit Role',
  rolesmgmt_title_privilege: 'Privileges',
  rolesmgmt_title_roles: 'Roles List',
  privilege_success_message: 'Privilege updated successfully',
  updateProfile_text_address: 'Address',
  updateProfile_text_city: 'City',
  updateProfile_text_companyName: 'Company Name',
  updateProfile_text_country: 'Country',
  updateProfile_text_designation: 'Designation',
  updateProfile_text_email: 'Email',
  updateProfile_text_firstName: 'First Name',
  updateProfile_text_gst: 'GST',
  updateProfile_text_language: 'Language',
  updateProfile_text_lastName: 'Last Name',
  updateProfile_text_phoneNumber: 'Phone Number',
  updateProfile_text_pinCode: 'Pin Code',
  updateProfile_text_role: 'Role',
  updateProfile_text_state: 'State',
  updateProfile_title_text: 'User Profile',
  userprofile_button_changePassword: 'Change Password',
  userprofile_button_updateProfile: 'Update Profile',
  usermgmt_button_active_user: 'Active user',
  usermgmt_button_in_active_user: 'In-active user',
  roles_title_add: 'Add Role',
  required_error_prefix_message: 'Please enter valid details in',
  common_save_message: 'Data saved successfully.',
  common_update_message: 'Data updated successfully.',
  common_delete_message: 'Data deleted successfully.',
  captchaValidation: 'Captcha is required',
  requiredText: 'This field is required',
  valid_pinCode_error: 'Please enter valid pin code',
  privilege_select_all_text: 'Select All',
  common_urlError: 'Please enter a valid URL.',
  common_validation_error: 'Please enter valid input',
  insufficient_length: min => `Insufficient Length. Min length: ${min} characters required.`,
  exceeded_character_limit: max => `Exceeded Character Limit. Max length: ${max} characters.`,
  common_number_validation_error: 'Please enter a valid number',
  common_min_value_error: min => `Value cannot be less than ${min}`,
  common_max_value_error: max => `The value should not exceed ${max}`,
  user_profile_edit_profile: 'Update Profile',
  user_profile_change_password: 'Change Password',
  password_validation_message:
    'Password length should be between 8 and 15 characters and must contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character',
  change_password_error_message: 'New Password and Confirm Password are not same',
  email_invalid_error: 'Please enter a valid email address',
  phone_no_invalid_error: 'Please enter a valid phone number',
  logoutText: 'Logout',
  date_format_error: 'Please enter a valid date',
  min_date_error: fomattedMinDate => `Please enter date greater than ${fomattedMinDate}`,
  max_date_error: fomattedMaxDate => `Please enter date less than ${fomattedMaxDate}`,
  error_page_back_to_add_organization_text: 'Back to Add Organization',
  error_page_desc_text: 'Do not worry though, there is always a way to go back home',
  error_page_back_home_text: 'Back Home',
  error_page_service_unavailable_text: 'Service Unavailable !!!',
  page_not_found_desc_text: 'Sorry, The page you’re looking for doesn’t exist',
  upload_button_text: 'Choose Files',
  delete_success_message: 'Row has been deleted Successfully',
  cancelText: 'Cancel',
  anotherVerificationCodeText: 'Didn’t get the code? No problem, we will send you another verification code.',
  resendItButtonText: 'Resend It',
  invalid_email_message: email => `"${email}" is not a valid email address`,

  // -----------------------------------------------------

  rewardsText: 'Bumblegift.com',
  menu_text_product: 'Product',
  menu_text_coupon: 'Coupon',
  menu_text_orders: 'Orders',
  ImportCouponsText: 'Upload Coupons',
  DownloadOrders: 'Download Orders',
};
