import { FC, useState } from "react";
import { validateInput } from "../../utils/validators/Validators";
import {
  FormControl,
  FormHelperText,
  Typography,
  useTheme,
} from "@mui/material";
import { FormValidators } from "../Form";
import Select from "react-select";
import './styles.scss'
import strings from "common/Translation/Translate";

interface OwnProps {
  field?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  typeValue?: string;
  onChange: (
    value: string,
    field: string,
    error?: { error: boolean; message: string }
  ) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: string | boolean;
  isSearchable?:boolean;
  label?: string;
  disableFuture?: boolean;
  labelId?: string;
  inputValue: any;
  options: any[];
  inputProps?: any,
  className?: string,
  style?: any,
  isMulti?: boolean,
  isClearable?: boolean
  id: any,
}
const MultiSelect: FC<OwnProps> = ({
  hasError,
  validators,
  onChange,
  field,
  inputValue,
  options,
  fieldError,
  disabled,
  className,
  label,
  id,
  style,
  placeholder,
  isClearable = true,
  isSearchable=true,
  isMulti,
}) => {
  const theme = useTheme();
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const handleChange = (selectedOption: any) => {
    const inputError = validateInput(validators as FormValidators[], selectedOption);
    setError(inputError);
    onChange(selectedOption, field as string, inputError);
  };
  
  const customMessage = () => !error && hasError && fieldError ? strings.requiredText : ""
  return (
    <FormControl
      sx={{
        '.select': {
          fontFamily: theme.typography.fontFamily,
        },
      }}
      error={!!((error && error.error) || (!error && hasError && fieldError))}
      className={`dropdown ${className} ${((error && error.error) || (!error && hasError && fieldError) ? 'mainError' : '')}`}
      fullWidth
    >
      {(isMulti ? inputValue.length > 0 : inputValue !== '') && <Typography variant="body2" className="labels">{label}</Typography>}
      <Select
        className="select"
        options={options}
        styles={style}
        isMulti={isMulti}
        isDisabled={disabled}
        isSearchable={isSearchable}
        id={id}
        isClearable={isClearable}
        value={inputValue}
        placeholder={placeholder}
        closeMenuOnSelect={isMulti ? false : true}
        onChange={(value) => {
          handleChange(value);
        }}
      />
      <FormHelperText>
        {error && error.error ? error.message : customMessage()}
      </FormHelperText>
    </FormControl>
  );
};
export default MultiSelect;
