// const url = process.env.REACT_APP_ENV === 'PROD';
const Config = {
  BaseURL: process.env.REACT_APP_BASE_API_URL,
  // BaseURL: url ? process.env.REACT_APP_BASE_API_URL : `${window.location.protocol}//${window.location.hostname}`,
  // BaseURL: `${window.location.protocol}//${window.location.hostname}/service`,
  DeleteDelay: 3000,
  DefaultLimit: 10,
  REACT_APP_CLIENT: process.env.REACT_APP_CLIENT,
  REACT_APP_SECRET: process.env.REACT_APP_SECRET,
};

export default Config;
