import { Validators } from '../../../utils/validators';
import { FormModel } from '../../../components/Form';

export const ForgotForm = (strings: any): FormModel[] => {
  return [
    {
      label: 'Email ID',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'email',
      validators: [
        { check: Validators.validateMandatoryField },
        { check: Validators.validateString },
        { check: Validators.validateEmail },
      ],
      responsive: { xs: 12 },
      required: true,
    },
  ];
};
