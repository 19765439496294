import { FC, useEffect, useState, useCallback } from 'react';
import {
  AlertColor,
  Box,
  Container,
  IconButton,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PageLoader from '../../../components/PageLoader';
import { useSelector } from 'react-redux';
import { Privilege } from '../../../utils/redux/reducer/authentication-slice';
import strings from 'common/Translation/Translate';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/axios';
import ListPage from '../CommonPage';
import { privileges } from 'utils/constants/privileges';
import DrawerMenu from 'components/DrawerMenu';
import { FormComponent } from 'utils/validators/HelperComponents';
import { getDrawerWidth } from 'utils/validators/HelperFunctions';
import Upload from 'components/Upload/Upload';
import PrimaryButton from 'components/Button/PrimaryButton';
import Notification from 'components/Notification';
import { CouponFilterForm } from './FilterForms/CouponFilterForm';
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataTable } from '../Product/DataTable';
import { ProductFilterForm } from '../Product/AddEditProduct/ProductFilterForm';
import { ToolTip } from 'components/Tooltip/ToolTip';
import VariantList from '../Product/Variant/VariantList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CouponList from './CouponList';
import { VariantFilterFormCoupon } from './FilterForms/VariantFilterFormCoupon';

const VariantDataTable = ({ productId }: { productId: any }) => {
  const theme = useTheme();
  const IsPrivilege = useSelector(Privilege);
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [variantListData, setVariantListData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const getVariantListFunc = useCallback(
    async (filterData: any) => {
      try {
        setLoading(true);
        const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
        const { data } = await axiosInstance.post(URLS.variantList(productId), body);
        setVariantListData(data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [URLS.variantList],
  );

  useEffect(() => {
    (async () => {
      await getVariantListFunc(null);
    })();
  }, [getVariantListFunc]);

  const ProductTablePageConfig = {
    header: 'Variants',
    columnHeaders: [''],
    isExpandable: true,
    innerComponentPrivilege: privileges.view_coupon,
    hideTableHead: true,
    filterForm: VariantFilterFormCoupon(strings, 12)
  }

  const VariantRow = ({ row, open, setOpen, deleteUser, setOuterRowId }: { row: any, open: any, setOpen: any, deleteUser: any, setOuterRowId: any }) =>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      {IsPrivilege?.includes(privileges.view_coupon) && (
        <TableCell>
          <Box onClick={() => { setOpen(!open); setOuterRowId(row.id) }}>
            {open ?
              <Box display={'flex'} alignItems={'center'} gap={3}>
                <KeyboardArrowUpIcon />
                <Typography>{row.name} - {row.variantCode}</Typography>
              </Box>
              :
              <Box display={'flex'} alignItems={'center'} gap={3}>
                <KeyboardArrowDownIcon />
                <Typography>{row.name} - {row.variantCode}</Typography>
              </Box>}
          </Box>
        </TableCell>)}
    </TableRow>

  return (<>
    {loading && <PageLoader />}
    {message.display && (
      <Notification
        isOpen={message.display}
        message={message.message}
        severity={message.severity as AlertColor}
        closeNotification={closeNotification}
      />
    )}
    {Object.keys(variantListData).length > 0 &&
      <DataTable
        pageResponse={variantListData}
        getList={getVariantListFunc}
        pageConfig={ProductTablePageConfig}
        outerChildren={
          (row: any, open: any, setOpen: any, deleteUser: any, setOuterRowId: any) =>
            <VariantRow row={row} open={open} setOpen={setOpen} deleteUser={deleteUser} setOuterRowId={setOuterRowId} />}
        innerChildren={(outerRowId: any) => (
            <CouponList id={outerRowId}/>
        )}
      />}
  </>)
};
export default VariantDataTable;
