import { AlertColor, useMediaQuery, useTheme } from '@mui/material';
import strings from 'common/Translation/Translate';
import PrimaryButton from 'components/Button/PrimaryButton';
import DrawerMenu from 'components/DrawerMenu';
import Notification from 'components/Notification';
import Upload from 'components/Upload/Upload';
import { useCallback, useEffect, useState } from 'react';
import axiosInstance from 'utils/axios';
import { images } from 'utils/constants/images';
import { privileges } from 'utils/constants/privileges';
import { URLS } from 'utils/constants/urls';
import { getDrawerWidth } from 'utils/validators/HelperFunctions';
import ListPage from '../../CommonPage';
import AddEditVariant from './AddEditVariant/AddEditVariant';
import { VariantFilterForm } from './AddEditVariant/VariantFilterForm';

const VariantList = ({ productId }: { productId: any }) => {
  const theme = useTheme();
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [pageResponse, setPageResponse] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [deletedImage, setDeletedImage] = useState<any>([]);
  const [openUploadDrawer, setOpenUploadDrawer] = useState<boolean>(false);
  const [id, setId] = useState<any>(null);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const pageConfig = {
    title: 'Variant',
    listApi: URLS.variantList(productId),
    AddButtonText: 'Add New Variant',
    addButtonFunc: () => {
      setOpenDrawer(true);
      setId(null);
    },
    editButtonFunc: (id: any) => {
      setOpenDrawer(true);
      setId(id);
    },
    deleteApi: URLS.variantListById,
    deleteOtherParam: productId,
    addPrivilege: privileges.add_variant,
    editPrivilege: privileges.edit_variant,
    deletePrivilege: privileges.delete_variant,
    uploadFunc: () => setOpenDrawer(true),
    tableColumn: [
      { field: 'name', headerName: 'Name', flex: 1, sortable: false },
      { field: 'variantCode', headerName: 'Variant Code', flex: 1, sortable: false },
      // { field: 'loyaltyPoints', headerName: 'Loyalty Points', flex: 1, sortable: false },
      { field: 'price', headerName: 'price', flex: 1, sortable: false },
      // { field: 'description', headerName: 'Description', flex: 5, sortable: false },
    ],
    customImage: {
      imageUrl: images.uploadIcon,
      tooltip: 'Upload Coupons',
      handleCustomImageMethod: (id: any) => {
        setOpenUploadDrawer(true);
        setId(id);
      },
      privilege: privileges.import_coupon,
    },
    isActionCol: true,
    filterForm: VariantFilterForm(strings, 12),
  };

  const getList = useCallback(
    async (filterData: any) => {
      try {
        setLoading(true);
        const body = filterData || { page: 1, size: 5, filter: [], sort: ['createdAt:desc'] };
        const { data } = await axiosInstance.post(pageConfig.listApi, body);
        setPageResponse(data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [pageConfig.listApi],
  );

  useEffect(() => {
    (async () => {
      await getList(null);
    })();
  }, [getList]);

  const handleDrawerClose = () => setOpenDrawer(false);

  const getFileDetails = (
    value: string,
    field: string,
    error?: { error: boolean; message: string },
    isDeleted?: any,
  ) => {
    const deletedArray = [...deletedImage];
    if (Array.isArray(isDeleted)) {
      deletedArray.push(isDeleted[0].name);
      setDeletedImage(deletedArray);
      setDeletedImage([]);
      setUploadedFile(value);
    } else {
      setUploadedFile(value);
    }
  };

  const handleUpload = async () => {
    try {
      if (uploadedFile === null) {
        setMessage({
          display: true,
          severity: 'error',
          message: 'Please upload a file',
        });
      } else {
        setLoading(true);
        const body = new FormData();
        uploadedFile.forEach((file: any) => {
          body.append('file', file);
        });
        body.append('deletedFiles', deletedImage);
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const { status } = await axiosInstance.post(URLS.uploadCoupon(productId, id), body, config);
        if (status === 200) {
          setOpenUploadDrawer(false);
          setUploadedFile(null);
          setMessage({
            display: true,
            severity: 'success',
            message: 'File uploaded successfully',
          });
          await getList(null);
        }
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data.message,
      });
    }
  };

  return (
    <>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <ListPage loading={loading} pageConfig={pageConfig} pageResponse={pageResponse} getList={getList} />
      <DrawerMenu
        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screenSizeUpLg)}
        open={openDrawer}
        handleClose={handleDrawerClose}
        closeButton={true}
        title={id ? 'Edit Variant' : 'Add Variant'}>
        <AddEditVariant id={id} productId={productId} handleDrawerClose={handleDrawerClose} getList={getList} />
      </DrawerMenu>
      <DrawerMenu
        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screenSizeUpLg)}
        open={openUploadDrawer}
        handleClose={() => setOpenUploadDrawer(false)}
        closeButton={true}
        title={strings.ImportCouponsText}>
        <Upload inputValue={uploadedFile} textChange={getFileDetails} accept={'text/csv'} />
        <PrimaryButton onClick={handleUpload} sx={{ width: '30%' }}>
          Upload
        </PrimaryButton>
      </DrawerMenu>
    </>
  );
};
export default VariantList;
