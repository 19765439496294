import React, { FC, useMemo, useState, useRef, RefObject, useCallback } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  Box,
  TableContainer,
  // TableFooter,
  Container,
  useTheme,
  Popover,
  TablePagination,
  Button,
  TableHead,
  TableRow,
  Stack,
  TableSortLabel,
  IconButton,
  Chip,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useNavigate, Link } from 'react-router-dom';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import AddIcon from '@mui/icons-material/Add';
import { images } from '../../../utils/constants/images';
import { useSelector } from 'react-redux';
import { Privilege } from '../../../utils/redux/reducer/authentication-slice';
import Delete from './Delete';
import strings from '../../../common/Translation/Translate';
import './styles.scss';
import { removeErrorFieldsFromValues } from '../../../utils/validators';
import Form, { FormDataModel } from '../../../components/Form';
import { ToolTip } from '../../../components/Tooltip/ToolTip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PageLoader from '../../../components/PageLoader';
import useWindowSize from 'utils/hooks/useWindowSize';
// import { Scrollbar } from "react-scrollbars-custom";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export interface PageConfigModel {
  title: string;
  AddButtonText?: string;
  AddButtonUrl?: any;
  isActionCol?: boolean;
  editButtonUrl?: string;
  listApi: string;
  deleteApi?: any | null;
  editPrivilege?: string;
  deletePrivilege?: string;
  blockUserPrivilege?: string;
  addPrivilege?: string | null;
  sortValue?: { sortOrder?: number; sortBy?: string };
  tableColumn: any[];
  dropdownData?: any[];
  uploadPrivilege?: string;
  uploadButtonText?: string;
  downloadPrivilege?: string;
  downloadButtonText?: string;
  downloadFunc?: any;
  uploadFunc?: any;
  addButtonFunc?: any;
  editButtonFunc?: any;
  deleteOtherParam?: any;
  customImage?: {
    imageUrl: string;
    buttonText?: string;
    privilege: string;
    tooltip?: string;
    handleCustomImageMethod: (id: number) => void;
  };
  sortHandler?: (sort: string) => void;
  toggleUser?: (id: number) => void;
  filterForm?: any;
}
export interface ListPageProps {
  pageConfig: PageConfigModel;
  pageResponse: any;
  getList: any;
  loading?: boolean;
  isWhiteSpace?: boolean;
}
const ListPage: FC<ListPageProps> = ({ pageConfig, pageResponse, getList, loading = false, isWhiteSpace = true }) => {
  const Navigate = useNavigate();
  // const location = useLocation();
  const mobile = useWindowSize();
  let filterRef: RefObject<Form | null | undefined> = useRef();
  const theme = useTheme();
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const IsPrivilege = useSelector(Privilege);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const [index, setIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState<any>([]);
  const [savedFilter, setSavedFilter] = useState<any>([]);
  const [sort, setSort] = useState<any>(false);
  const deleteUser = async (id: string) => {
    setSelectedId(id);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  //console.log('pageconfig.uploadPrivilege: ', pageConfig)
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter([]);
    setIndex(newPage);
    const body = {
      page: newPage + 1,
      size: pageSize,
      filter: [],
      // eslint-disable-next-line no-useless-concat
      sort: pageConfig?.sortValue?.sortBy
        ? [`${pageConfig?.sortValue?.sortBy as any}:` + `${pageConfig?.sortValue?.sortOrder}`]
        : ['createdAt:desc'],
    };
    // Navigate(`${location.pathname}?page=${newPage}`)
    getList(body);
  };

  const pageSizeChange = (event: any) => {
    const value = event.target.value;
    setIndex(0);
    setFilter([]);
    setPageSize(value);
    const body = {
      page: 1,
      size: value,
      filter: [],
      // eslint-disable-next-line no-useless-concat
      sort: pageConfig?.sortValue?.sortBy
        ? [`${pageConfig?.sortValue?.sortBy as any}:` + `${pageConfig?.sortValue?.sortOrder}`]
        : ['createdAt:desc'],
    };
    getList(body);
  };
  const deleteModal = useMemo(() => {
    return (
      <Delete
        updateList={() =>
          getList({
            page: index + 1,
            size: pageSize,
            filter: filter || [],
            // eslint-disable-next-line no-useless-concat
            sort: pageConfig?.sortValue?.sortBy
              ? [`${pageConfig?.sortValue?.sortBy as any}:` + `${pageConfig?.sortValue?.sortOrder}`]
              : ['createdAt:desc'],
          })
        }
        closeModal={closeModal}
        openModal={openModal}
        deleteUrl={pageConfig.deleteApi && (pageConfig.deleteOtherParam ? pageConfig.deleteApi(pageConfig.deleteOtherParam, selectedId) : pageConfig.deleteApi(selectedId))}
      />
    );
  }, [filter, getList, index, openModal, pageConfig, pageSize, selectedId]);
  const handleFilter = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const applyFilter = useCallback(
    async (reset: boolean) => {
      const filters: any = [];
      if (reset) {
        setFilter(null);
      } else {
        const { getFormData } = filterRef.current as {
          getFormData: () => { formData: FormDataModel; isFormValid: boolean };
        };
        const { formData } = getFormData();
        const filter_data = removeErrorFieldsFromValues(formData);
        setFilter(filter_data);
        Object.keys(filter_data).forEach(key => {
          if (!!filter_data[key]) {
            // filters?.filter((item: any) => item !== null);
            if (filters.length > 0) {
              filters.push('and');
            }
            filters.push(filter_data[key] ? `${key}:cic:${filter_data[key]}` : null);
          }
        });
      }
      const newFilter = filters?.filter((item: any) => item !== null);
      setSavedFilter(newFilter);
      const body = {
        page: 1,
        size: pageSize,
        filter: newFilter || [],
        // eslint-disable-next-line no-useless-concat
        sort: pageConfig?.sortValue?.sortBy
          ? [`${pageConfig?.sortValue?.sortBy as any}:` + `${pageConfig?.sortValue?.sortOrder}`]
          : ['createdAt:desc'],
      };
      await getList(body);
      handleCloseFilter();
    },
    [getList, pageConfig?.sortValue?.sortBy, pageConfig?.sortValue?.sortOrder, pageSize],
  );
  const handleSort = async (localField: any, field: any, direction: any) => {
    const column = localField ? localField : field;
    setSort(!direction);
    pageConfig?.sortHandler && pageConfig?.sortHandler(column?.field);
    const dir = direction ? 'desc' : 'asc';
    const body = {
      page: 1,
      size: pageSize,
      filter: savedFilter,
      sort: column ? [`${column}:${dir}`] : ['createdAt:desc'],
    };
    await getList(body);
  };
  const popover = useMemo(() => {
    return (
      <Box>
        <Grid container sx={{ mx: mobile ? 0.5 : 2, ml: 0, px: 2 }}>
          <Grid item xs={5}>
            <Typography sx={{ fontSize: mobile ? 16 : 24, fontWeight: 500 }}>{strings.filter_text}</Typography>
          </Grid>
          <Grid item xs={5} textAlign={'right'}>
            <Button
              size="small"
              variant={'outlined'}
              onClick={() => applyFilter(true)}
              sx={{ p: mobile ? 0 : 1, ml: mobile ? 0 : 1 }}>
              <Typography fontSize="small" variant={'body2'} whiteSpace="nowrap">
                {screenSizeUpMd ? strings.clear_filter_text : strings.clearText}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={2} textAlign={'right'}>
            <Button variant={'text'} onClick={handleCloseFilter}>
              <Close />
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ my: mobile ? 0.5 : 2, width: 'calc(100% + 16px)' }} />
        <Grid container>
          <form className="w-100" onSubmit={() => applyFilter(false)}>
            <Grid item xs={12} sx={{ p: 1 }}>
              <Form ref={filterRef as RefObject<Form>} model={pageConfig.filterForm} values={filter} />
              <Button
                type="submit"
                variant="contained"
                sx={{ my: 2, color: '#ffffff' }}
                fullWidth
                onClick={() => applyFilter(false)}>
                {strings.continue_text}
              </Button>
            </Grid>
          </form>
        </Grid>
      </Box>
    );
  }, [mobile, screenSizeUpMd, pageConfig.filterForm, filter, applyFilter]);
  const anchor_id = open ? 'simple-popover' : undefined;
  const handlefilterData = (e: any) => {
    setAnchorEl(e.target);
  };
  const headerWidth = () => {
    if (pageConfig.filterForm && IsPrivilege?.includes(pageConfig.addPrivilege)) return 8;
    else if (pageConfig.filterForm || IsPrivilege?.includes(pageConfig.addPrivilege)) return 10;
    else return 12;
  };

  const EditButton = ({ id }: { id: any }) => {
    if (pageConfig.editButtonUrl) {
      return <Link to={`${pageConfig.editButtonUrl}/${id}`}>
        <ToolTip title={strings.editText}>
          <EditIcon fontSize="small" />
        </ToolTip>
      </Link>
    } else if (pageConfig.editButtonFunc) {
      return <ToolTip title={strings.editText}>
        <EditIcon
          fontSize='small'
          color="primary"
          onClick={() => pageConfig.editButtonFunc(id)}
        />
      </ToolTip>
    } else {
      return <ToolTip title={strings.editText}>
        <EditIcon fontSize="small" />
      </ToolTip>
    }
  }
  return (
    <Container
      maxWidth={false}
      sx={{
        '&.MuiContainer-root': {
          pl: 0,
          pr: 0,
        },
      }}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={headerWidth()} sm={6}>
          <Typography sx={{ fontSize: screenSizeDownSm ? 22 : 24, fontWeight: screenSizeDownSm ? 700 : 500 }}>
            {pageConfig.title}
          </Typography>
        </Grid>
        <Grid justifyContent="end" item xs={12 - headerWidth()} sm={6} textAlign={'right'}>
          {pageConfig.filterForm &&
            (!screenSizeDownSm ? (
              <PrimaryButton sx={{ mr: 2 }} onClick={handleFilter} fullWidth={false}>
                <FilterAltOutlinedIcon />
                {/* {mobile ? '' : strings.filter_text} */}
              </PrimaryButton>
            ) : (
              <FilterAltIcon style={{ marginRight: 10 }} onClick={handleFilter} color="primary" />
            ))}
          {IsPrivilege?.includes(pageConfig.uploadPrivilege) &&
            (!screenSizeDownSm ? (
              <PrimaryButton
                variant="contained"
                onClick={pageConfig.uploadFunc}
                sx={{ color: '#ffffff', width: 'auto' }}>
                <CloudUploadIcon />
                &nbsp;{mobile ? '' : pageConfig.uploadButtonText}
              </PrimaryButton>
            ) : (
              <CloudUploadIcon
                color="primary"
                onClick={pageConfig.uploadFunc}
              />
            ))}
          {IsPrivilege?.includes(pageConfig.downloadPrivilege) &&
            (!screenSizeDownSm ? (
              <PrimaryButton
                variant="contained"
                onClick={pageConfig.downloadFunc}
                sx={{ color: '#ffffff', width: 'auto' }}>
                <CloudDownloadIcon />
                &nbsp;{mobile ? '' : pageConfig.downloadButtonText}
              </PrimaryButton>
            ) : (
              <CloudDownloadIcon
                color="primary"
                onClick={pageConfig.downloadFunc}
              />
            ))}
          {IsPrivilege?.includes(pageConfig.addPrivilege) &&
            (!screenSizeDownSm ? (
              <PrimaryButton
                variant="contained"
                onClick={() => {
                  if (pageConfig.addButtonFunc) pageConfig.addButtonFunc()
                  else if (pageConfig.AddButtonUrl) Navigate(pageConfig.AddButtonUrl);
                  else { }
                }}
                sx={{ color: '#ffffff', width: 'auto' }}>
                <AddIcon />
                &nbsp;{mobile ? '' : pageConfig.AddButtonText}
              </PrimaryButton>
            ) : (
              <AddIcon
                color="primary"
                onClick={() => {
                  if (pageConfig.addButtonFunc) pageConfig.addButtonFunc()
                  else if (pageConfig.AddButtonUrl) Navigate(pageConfig.AddButtonUrl);
                  else { }
                }}
              />
            ))}
        </Grid>
      </Grid>
      {filter &&
        Object.entries(filter).map(
          ([key, value]: any) =>
            value !== '' && (
              <Chip
                size={mobile ? 'small' : 'medium'}
                onClick={handlefilterData}
                key={key}
                sx={{
                  textTransform: 'capitalize',
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  mr: mobile ? 1 : 2,
                  my: mobile ? 0.5 : 1,
                  fontSize: mobile ? '10px' : '12px',
                  fontWeight: 700,
                }}
                color="error"
                label={`${key} : ${value}`}
              />
            ),
        )}
      <Divider sx={{ mt: 1 }} />
      {pageConfig?.filterForm && (
        <Popover
          onClose={() => {
            setAnchorEl(null);
          }}
          sx={{ m: 4, [`& .MuiPopover-paper`]: { borderRadius: 5, p: 1, width: mobile ? '300px' : '400px' } }}
          id={anchor_id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          {popover}
        </Popover>
      )}
      {loading && <PageLoader />}
      {pageResponse?.content?.length > 0 ? (
        <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: 0 }}>
          <TableContainer
            sx={{
              borderRadius: 0,
              // boxShadow: 0
            }}
            component={Paper}>
            {/* <Scrollbar style={{ width: '100%', height: '70vh' }}> */}
            <Table
              stickyHeader
            // sx={{
            //   borderSpacing: "0 1rem",
            //   borderCollapse: "separate",
            // }}
            >
              <TableHead
                sx={{
                  '.MuiTableCell-root': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    border: `1px solid ${theme.palette.secondary.main}`,
                    height: '58px',
                    borderRadius: 0,
                    '.MuiTableSortLabel-root:active, .MuiTableSortLabel-root:hover, .MuiTableSortLabel-root:focus': {
                      color: theme.palette.secondary.contrastText,
                    },
                  },
                }}>
                <TableRow>
                  {pageConfig.tableColumn.map((column: any, index: number) => (
                    <TableCell
                      key={column.field}
                    // sx={{
                    //   borderTop: "none",
                    //   borderBottom: "none",
                    //   py: 0,
                    //   color: "#656565",
                    // }}
                    >
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" spacing={0.5} alignItems="center">
                          <TableSortLabel
                            hideSortIcon={!column?.sortable}
                            active={pageConfig?.sortValue?.sortBy === column?.field}
                            direction={sort ? 'desc' : 'asc'}
                            onClick={() => (column?.sortable ? handleSort(column.localField, column.field, sort) : {})}>
                            <Stack direction="row" alignItems="center" spacing={0.5}>
                              <Typography whiteSpace="nowrap" fontWeight={600} fontSize="14px">
                                {column.headerName}
                              </Typography>
                            </Stack>
                          </TableSortLabel>
                        </Stack>
                      </Stack>
                    </TableCell>
                  ))}
                  {pageConfig.isActionCol && (
                    <TableCell
                      sx={{
                        textAlign: 'right',
                      }}>
                      {strings.action_text}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {pageResponse?.content?.length > 0 &&
                  pageResponse?.content?.map((item: any, indexValue: number) => (
                    <TableRow
                      key={item.id}>
                      {pageConfig.tableColumn.map((_header: any, i: number) => (
                        <TableCell sx={{ whiteSpace: isWhiteSpace ? 'nowrap' : 'inherit' }} key={_header.field}>
                          {item[_header['field']]}
                        </TableCell>
                      ))}
                      <TableCell
                        className="last-row"
                        sx={{
                          textAlign: 'right',
                          div: {
                            display: 'flex',
                            justifyContent: 'end',
                            verticalAlign: 'middle',
                          },
                        }}>
                        <Stack
                          sx={{ svg: { color: theme.palette.primary.main }, alignItems: 'flex-start' }}
                          direction="row"
                          spacing={2}>
                          {IsPrivilege?.includes(pageConfig?.customImage?.privilege) &&
                            (pageConfig?.customImage?.imageUrl !== '' ? (
                              <ToolTip title={pageConfig?.customImage?.tooltip}>
                                <img
                                  className={'cursor-pointer'}
                                  src={pageConfig?.customImage && pageConfig?.customImage?.imageUrl}
                                  alt="reset"
                                  width="20px"
                                  height="20px"
                                  onClick={() =>
                                    pageConfig?.customImage && pageConfig?.customImage.handleCustomImageMethod(item.id)
                                  }
                                />
                              </ToolTip>
                            ) : (
                              <PrimaryButton
                                variant="contained"
                                onClick={() =>
                                  pageConfig?.customImage && pageConfig?.customImage.handleCustomImageMethod(item.id)
                                }
                                sx={{ color: '#ffffff', width: 'auto', minWidth: '145px' }}>
                                {pageConfig?.customImage?.buttonText}
                              </PrimaryButton>
                            ))}
                          {IsPrivilege?.includes(pageConfig.blockUserPrivilege) && (
                            <ToolTip
                              title={`${item?.enabled
                                ? strings.usermgmt_button_active_user
                                : strings.usermgmt_button_in_active_user
                                }`}>
                              <img
                                className={'cursor-pointer'}
                                src={item?.enabled ? images.activeToggleIcon : images.inActiveToggleIcon}
                                alt="toggle user"
                                width="20px"
                                height="20px"
                                onClick={() => pageConfig.toggleUser && pageConfig.toggleUser(item.id as any)}
                              />
                            </ToolTip>
                          )}
                          {/* {() => {
                            if (pageConfig.editButtonFunc) pageConfig.editButtonFunc()
                            else if (pageConfig.AddButtonUrl) Navigate(pageConfig.AddButtonUrl);
                            else { }
                          }}
                          {IsPrivilege?.includes(pageConfig.editPrivilege) && (
                            < Link to={`${pageConfig.editButtonUrl}/${item.id}`}>
                          <ToolTip title={strings.editText}>
                            <EditIcon fontSize="small" />
                          </ToolTip>
                        </Link>
                          )} */}
                          {IsPrivilege?.includes(pageConfig.editPrivilege) && (
                            <EditButton id={item.id} />
                          )}
                          {IsPrivilege?.includes(pageConfig.deletePrivilege) && (
                            <ToolTip title={strings.deleteText}>
                              <IconButton sx={{ p: 0, pr: 2 }} onClick={() => deleteUser(item.id)} aria-label="theme">
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                              </IconButton>
                            </ToolTip>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {/* </Scrollbar> */}
          </TableContainer>
          {(pageResponse.number > 1 ||
            pageResponse.numberOfElements > pageResponse.size - 1 ||
            pageResponse.totalPages > 1) && (
              <TablePagination
                // colSpan={5}
                count={pageResponse.totalElements}
                rowsPerPage={pageSize}
                page={index}
                onRowsPerPageChange={e => {
                  pageSizeChange(e);
                }}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            )}
        </Paper>
      ) : (
        !loading && (
          <Typography
            sx={{
              p: 2,
              mt: 5,
              borderRadius: 1,
              bgcolor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            }}
            variant="h6">
            {strings.no_data_available}
          </Typography>
        )
      )
      }
      {openModal && deleteModal}
    </Container >
  );
};
export default ListPage;
