import { FormControl, FormHelperText, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import CKEditor from 'react-ckeditor-component';
import { validateInput } from "../../utils/validators/Validators";
import strings from 'common/Translation/Translate';


const TextEditor = ({ value, label, field, onChange, validators, hasError, fieldError, defaultSource = false, disabled  }) => {
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    const newContent = event.editor.getData();
    const inputError = validateInput(validators, newContent);
    setError(setError);
    onChange(newContent, field, inputError);
  };
  //console.log('disabled: ', disabled)
  const editSource = mode => {
    const editor = mode.editor;
    if (editor.mode === 'source') {
      const editable = editor._.editable;
      editable.attachListener(editable, 'input', e => {
        handleChange(mode);
      });
    } 
    disabled && editor.setReadOnly(true)    
  };
  const defaultModeConfig = defaultSource ? {
    startupMode: 'source'
  } : {};
  const customMessage = () => !error && hasError && fieldError ? strings.requiredText : ""
  return (
    <FormControl
      error={!!((error && error.error) || (!error && hasError && fieldError))}
      className={`dropdown`}
      fullWidth
    >
      {label && <Typography variant='h6' mb={2}>{label}</Typography>}
      <CKEditor
        type="classic"
        activeClass="p10"
        content={value}
        events={{
          change: handleChange,
          mode: editSource,
        }}
        config={{
          allowedContent: true,
          // toolbar: [],
         ...defaultModeConfig

        }}
      />
      <FormHelperText>
        {error && error.error ? error.message :  customMessage()}
      </FormHelperText>
    </FormControl>)
}
export default TextEditor;
