function Debounce(fn?: any, delay?: number) {
  let timer: any;
  return function () {
    let context: any,
    args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

export default Debounce;
