import { FC } from 'react';
import { Navigate, Route, Routes as Switch, useLocation } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { Authenticated } from '../utils/redux';
import Login from '../screens/Authentication/Login';
import ForgotPassword from '../screens/Authentication/ForgotPassword';
import ResetPin from '../screens/Authentication/ResetPin';
import AuthenticatedLayout from './AuthenticatedLayout';
import { routes } from '../utils/constants/routes';
import UserProfile from 'screens/Authenticated/UserProfile';
import Orders from 'screens/Authenticated/Orders';
import Product from 'screens/Authenticated/Product';
import AddEditProducts from 'screens/Authenticated/Product/AddEditProduct/AddEditProduct';
import EditProfile from 'screens/Authenticated/UserProfile/EditProfile';
import AddEditRole from 'screens/Authenticated/Role/RoleList/AddEditRole/AddEditRole';
import Privilege from 'screens/Authenticated/Role/RoleList/Privilege';
import { RoleList } from 'screens/Authenticated/Role/RoleList/RoleList';
import UserManagement from 'screens/Authenticated/UserManagement';
import AddEditUser from 'screens/Authenticated/UserManagement/AddUser/AddEditUser';
import Coupon from 'screens/Authenticated/Coupon';

const Root: FC = () => {
  let location = useLocation();

  const isLogin = useSelector(Authenticated);
  const { from } = location.state || { from: { pathname: routes.product } };
  return isLogin ? <Navigate to={from} /> : <Navigate to={routes.login} />;
};

const AuthenticatedScreens: FC<{ Component: FC }> = ({ Component }) => {
  const isLogin = useSelector(Authenticated);

  return isLogin ? <AuthenticatedLayout Component={Component} /> : <Navigate to={routes.login} />;
};

const Routes: FC = () => {
  return (
    <Grid item container flexDirection="column">
      <Grid className="pageWrapper" item sx={{ width: '100%' }}>
        <Paper elevation={0} square={false} className="paperWrapper">
          <Switch>
            <Route path={routes.root} element={<Root />} />
            <Route path={routes.login} element={<Login />} />
            <Route path={routes.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.resetPassword} element={<ResetPin />} />
            <Route path={routes.product} element={<AuthenticatedScreens Component={Product} />} />
            <Route path={routes.coupons} element={<AuthenticatedScreens Component={Coupon} />} />
            <Route path={routes.orders} element={<AuthenticatedScreens Component={Orders} />} />
            <Route path={routes.profile} element={<AuthenticatedScreens Component={UserProfile} />} />
            <Route path={`${routes.profile}/:id`} element={<AuthenticatedScreens Component={EditProfile} />} />
            <Route path={routes.addRole} element={<AuthenticatedScreens Component={AddEditRole} />} />
            <Route path={`${routes.editRole}/:id`} element={<AuthenticatedScreens Component={AddEditRole} />} />
            <Route path={`${routes.privilige}/:id`} element={<AuthenticatedScreens Component={Privilege} />} />
            <Route path={routes.roleList} element={<AuthenticatedScreens Component={RoleList} />} />
            <Route path={routes.users} element={<AuthenticatedScreens Component={UserManagement} />} />
            <Route path={routes.addUser} element={<AuthenticatedScreens Component={AddEditUser} />} />
            <Route path={`${routes.users}/:id`} element={<AuthenticatedScreens Component={AddEditUser} />} />
          </Switch>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Routes;
