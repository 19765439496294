import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { privileges } from '../../utils/constants/privileges';
import { routes } from '../../utils/constants/routes';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ReorderIcon from '@mui/icons-material/Reorder';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';

const config = (IsPrivilege: any, strings: any) => {

  //console.log('IsPrivilege: ', IsPrivilege, IsPrivilege?.includes(privileges.view_product))
  return [
    IsPrivilege?.includes(privileges.view_product)
      ? {
        key: '1',
        name: strings.menu_text_product,
        route: routes.product,
        icon: GridViewOutlinedIcon,
      }
      : '',
    IsPrivilege?.includes(privileges.view_coupon)
      ? {
        key: '2',
        name: strings.menu_text_coupon,
        route: routes.coupons,
        icon: CardGiftcardIcon,
      }
      : '',
    IsPrivilege?.includes(privileges.view_order)
      ? {
        key: '3',
        name: strings.menu_text_orders,
        route: routes.orders,
        icon: ReorderIcon,
      }
      : '',
      IsPrivilege?.includes(privileges.view_role)
      ? {
        key: '4',
        name: 'Role',
        route: routes.roleList,
        icon: ManageAccountsIcon,
      }
      : '',
      IsPrivilege?.includes(privileges.view_user)
      ? {
        key: '5',
        name: 'User Management',
        route: routes.users,
        icon: PeopleIcon,
      }
      : ''
  ]
};

export default config;
