import React, { FC, useState } from 'react';
import { DesktopDatePicker, DesktopDatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { validateInput } from '../../utils/validators/Validators';

import dayjs, { Dayjs } from 'dayjs';
import { identifiers } from '../../utils/constants/identifiers';
import { FormValidators } from '../Form';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt';
import 'dayjs/locale/en';
import strings from 'common/Translation/Translate';
import { useSelector } from 'react-redux';
import { defaultLanguage } from 'utils/redux/reducer/authentication-slice';

interface OwnProps {
  field?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  typeValue?: string;
  textChange: (value: string, field: string, error?: { error: boolean; message: string }) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: string | boolean;
  label?: string;
  disableFuture?: boolean;
  minDate?: any;
  maxDate?: any;
  size?: any;
}

const DateInput: FC<OwnProps & DesktopDatePickerProps<any, any>> = ({
  hasError,
  validators,
  textChange,
  field,
  value,
  placeholder,
  fieldError,
  disabled,
  disableFuture,
  minDate,
  maxDate,
  label,
  typeValue,
  size,
  ...rest
}) => {
  const selectedlangLocaleCode = useSelector(defaultLanguage).value.split('_')[0]
  const [error, setError] = useState<{
    error?: boolean;
    message?: string;
  } | null>({ error: hasError, message: fieldError as string });
  const handleChange = (value: any) => {
    if (value) {
      const inputError = validateInput(
        validators as FormValidators[],
        value instanceof dayjs ? (value as Dayjs).format(identifiers.date_input) : value,
      );
      textChange(
        value instanceof dayjs ? (value as Dayjs).format(identifiers.date_input) : (value as string),
        field as string,
        inputError,
      );
      if (inputError.error) {
        setError(inputError);
      }
    }
  };
  const onError = (error: any, value: any) => {
    const fomattedMinDate = minDate
      ? minDate.format(identifiers.date_format)
      : dayjs('1900-01-01').format(identifiers.date_format);
    const fomattedMaxDate = maxDate
      ? maxDate.format(identifiers.date_format)
      : dayjs('2099-12-12').format(identifiers.date_format);
    let message = '';
    if (error === 'minDate') {
      message = strings.min_date_error(fomattedMinDate);
    } else if (error === 'maxDate') {
      message = strings.max_date_error(fomattedMaxDate);
    } else {
      message = strings.date_format_error;
    }
    const errorMsg = { error: error != null ? true : false, message };

    textChange(value, field as string, errorMsg);
    setError(errorMsg);
  };
  // const onKeyDown = (e: any) => {
  //   e.preventDefault();
  //   // handleChange(e.target.value)
  // };
  const customMessage = () => (error && hasError && fieldError ? strings.requiredText : '');
  return (
    <LocalizationProvider adapterLocale={selectedlangLocaleCode} dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        {...rest}
        data-testid="datepicker"
        views={['year', 'month', 'day']}
        label={label}
        inputFormat={identifiers.date_format}
        value={value}
        onChange={(newValue: Dayjs | null) => handleChange(newValue)}
        disabled={disabled}
        className={`input-field ${fieldError}`}
        OpenPickerButtonProps={{ color: 'primary' }}
        disableFuture={disableFuture}
        minDate={minDate || null}
        maxDate={maxDate || null}
        onError={onError}
        renderInput={params => (
          <TextField
            {...params}
            size={size}
            helperText={error && error.error ? error.message : customMessage()}
            error={error?.error || (hasError && fieldError) ? true : false}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
