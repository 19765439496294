import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';

interface OwnProps {
  field?: string;
  label?: string;
  rest?: any;
}

const Section: FC<OwnProps> = ({ field, label, ...rest }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{ borderBottom: `1px dashed ${theme.palette.primary.main}` }}
      color="primary"
      mb={2}
      pb={1}
      fontWeight="bold">
      {label}
    </Typography>
  );
};

export default Section;
