import { FormModel } from 'components/Form';

export const ProductFilterForm = (strings?: any, width = 4): FormModel[] => {
  return [
    {
      label: 'Product name',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'name',
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
    {
      label: 'Product code',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'productCode',
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
  ];
};
