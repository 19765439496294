import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import { Button, Collapse, Divider, Grid, Popover, Typography, useMediaQuery, useTheme, Chip } from "@mui/material";
import { Box } from "@mui/system";
import { ToolTip } from "components/Tooltip/ToolTip";
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import PrimaryButton from "components/Button/PrimaryButton";
import { privileges } from "utils/constants/privileges";
import useWindowSize from "utils/hooks/useWindowSize";
import AddIcon from '@mui/icons-material/Add';
import { Privilege } from '../../../utils/redux/reducer/authentication-slice';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Delete from "../CommonPage/Delete";
import { Close } from "@mui/icons-material";
import strings from "common/Translation/Translate";
import Form, { FormDataModel } from "components/Form";
import { removeErrorFieldsFromValues } from "utils/validators";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';


type DataTableProps = {
    pageResponse: any,
    getList: any,
    pageConfig: any,
    outerChildren: any,
    innerChildren: any
}
export const DataTable = ({ pageResponse, getList, pageConfig, outerChildren, innerChildren }: DataTableProps) => {
    const Navigate = useNavigate();
    const theme = useTheme();
    const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { content } = pageResponse;
    const IsPrivilege = useSelector(Privilege);
    const mobile = useWindowSize();
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState<string>('');

    const [pageSize, setPageSize] = React.useState(10);
    const [index, setIndex] = React.useState(0);
    const [filter, setFilter] = React.useState<any>([]);
    const [savedFilter, setSavedFilter] = React.useState<any>([]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    let filterRef: React.RefObject<Form | null | undefined> = React.useRef();


    const pageSizeChange = (event: any) => {
        const value = event.target.value;
        setIndex(0);
        // setFilter([]);
        setPageSize(value);
        const body = {
            page: 1,
            size: value,
            filter: [],
            // eslint-disable-next-line no-useless-concat
            sort: ['createdAt:desc'],
        };
        getList(body);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setIndex(newPage);
        const body = {
            page: newPage + 1,
            size: pageSize,
            filter: [],
            // eslint-disable-next-line no-useless-concat
            sort: ['createdAt:desc'],
        };
        // Navigate(`${location.pathname}?page=${newPage}`)
        getList(body);
    };

    const deleteUser = async (id: string) => {
        setSelectedId(id);
        setOpenDeleteModal(true);
    };

    const closeModal = () => {
        setOpenDeleteModal(false);
    };

    const deleteModal = React.useMemo(() => {
        return (
            <Delete
                updateList={() =>
                    getList({
                        page: index + 1,
                        size: pageSize,
                        filter: filter || [],
                        // eslint-disable-next-line no-useless-concat
                        sort: pageConfig?.sortValue?.sortBy
                            ? [`${pageConfig?.sortValue?.sortBy as any}:` + `${pageConfig?.sortValue?.sortOrder}`]
                            : ['createdAt:desc'],
                    })
                }
                closeModal={closeModal}
                openModal={openDeleteModal}
                deleteUrl={pageConfig.deleteApi && pageConfig.deleteApi(selectedId)}
            />
        );
    }, [filter, getList, index, openDeleteModal, pageConfig, pageSize, selectedId]);

    const handleFilter = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const applyFilter = React.useCallback(
        async (reset: boolean) => {
            const filters: any = [];
            if (reset) {
                setFilter(null);
            } else {
                const { getFormData } = filterRef.current as {
                    getFormData: () => { formData: FormDataModel; isFormValid: boolean };
                };
                const { formData } = getFormData();
                const filter_data = removeErrorFieldsFromValues(formData);
                setFilter(filter_data);
                Object.keys(filter_data).forEach(key => {
                    if (!!filter_data[key]) {
                        // filters?.filter((item: any) => item !== null);
                        if (filters.length > 0) {
                            filters.push('and');
                        }
                        filters.push(filter_data[key] ? `${key}:cic:${filter_data[key]}` : null);
                    }
                });
            }
            const newFilter = filters?.filter((item: any) => item !== null);
            setSavedFilter(newFilter);
            const body = {
                page: 1,
                size: pageSize,
                filter: newFilter || [],
                // eslint-disable-next-line no-useless-concat
                sort: pageConfig?.sortValue?.sortBy
                    ? [`${pageConfig?.sortValue?.sortBy as any}:` + `${pageConfig?.sortValue?.sortOrder}`]
                    : ['createdAt:desc'],
            };
            await getList(body);
            handleCloseFilter();
        },
        [getList, pageConfig?.sortValue?.sortBy, pageConfig?.sortValue?.sortOrder, pageSize],
    );


    const popover = React.useMemo(() => {
        return (
            <Box>
                <Grid container sx={{ mx: mobile ? 0.5 : 2, ml: 0, px: 2 }}>
                    <Grid item xs={5}>
                        <Typography sx={{ fontSize: mobile ? 16 : 24, fontWeight: 500 }}>{strings.filter_text}</Typography>
                    </Grid>
                    <Grid item xs={5} textAlign={'right'}>
                        <Button
                            size="small"
                            variant={'outlined'}
                            onClick={() => applyFilter(true)}
                            sx={{ p: mobile ? 0 : 1, ml: mobile ? 0 : 1 }}>
                            <Typography fontSize="small" variant={'body2'} whiteSpace="nowrap">
                                {screenSizeUpMd ? strings.clear_filter_text : strings.clearText}
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={2} textAlign={'right'}>
                        <Button variant={'text'} onClick={handleCloseFilter}>
                            <Close />
                        </Button>
                    </Grid>
                </Grid>
                <Divider sx={{ my: mobile ? 0.5 : 2, width: 'calc(100% + 16px)' }} />
                <Grid container>
                    <form className="w-100" onSubmit={() => applyFilter(false)}>
                        <Grid item xs={12} sx={{ p: 1 }}>
                            <Form ref={filterRef as React.RefObject<Form>} model={pageConfig.filterForm} values={filter} />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ my: 2, color: '#ffffff' }}
                                fullWidth
                                onClick={() => applyFilter(false)}>
                                {strings.continue_text}
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Box>
        );
    }, [mobile, screenSizeUpMd, pageConfig.filterForm, filter, applyFilter]);
    const open = Boolean(anchorEl);
    const anchor_id = open ? 'simple-popover' : undefined;
    const handlefilterData = (e: any) => {
        setAnchorEl(e.target);
    };
    const headerWidth = () => {
        if (pageConfig.filterForm && IsPrivilege?.includes(pageConfig.addPrivilege)) return 8;
        else if (pageConfig.filterForm || IsPrivilege?.includes(pageConfig.addPrivilege)) return 10;
        else return 12;
    };

    function Row({ row, rowIndex }: { row: any, rowIndex: number }) {
        // const [open, setOpen] = React.useState(rowIndex === 0 ? true : false); 
        const [open, setOpen] = React.useState(false);
        const [outerRowId, setOuterRowId] = React.useState<any>(null);

        return (
            <>
                {pageConfig.isExpandable &&
                    <React.Fragment>
                        {outerChildren(row, open, setOpen, deleteUser, setOuterRowId)}
                        {IsPrivilege?.includes(pageConfig.innerComponentPrivilege) &&
                            <TableRow>
                                <TableCell sx={{ paddingBottom: 0, paddingTop: 0, paddingX: 0 }} colSpan={6}>
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        <Box sx={{ paddingY: 2, paddingX: 8, backgroundColor: '#ebe8e8' }}>
                                            {innerChildren(outerRowId)}
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        }
                    </React.Fragment>
                }
            </>
        )
    }
    return (
        <>
            <Grid container spacing={1} alignItems="center" mb={1} px={1}>
                <Grid item xs={6} md={4}>
                    <Typography sx={{ fontSize: screenSizeDownSm ? 22 : 24, fontWeight: screenSizeDownSm ? 700 : 500 }}>
                        {pageConfig.header}
                    </Typography>
                </Grid>
                <Grid justifyContent="end" item xs={6} sm={6} md={8} textAlign={'right'}>
                    {(IsPrivilege?.includes(privileges.import_product)
                        || IsPrivilege?.includes(privileges.import_variant)
                        || IsPrivilege?.includes(privileges.import_coupon)
                    ) &&
                        pageConfig.downloadtemplate &&
                        (!screenSizeDownSm ? (
                            <PrimaryButton sx={{ mr: 2 }} onClick={pageConfig.downloadtemplate} fullWidth={false}>
                                <FileDownloadIcon />
                            </PrimaryButton>
                        ) : (
                            <FileDownloadIcon style={{ marginRight: 10 }} onClick={pageConfig.downloadtemplate} color="primary" />
                        ))}
                    {IsPrivilege?.includes(privileges.import_product) && pageConfig.handleUpload &&
                        (!screenSizeDownSm ? (
                            <PrimaryButton sx={{ mr: 2 }} onClick={pageConfig.handleUpload} fullWidth={false}>
                                <FileUploadIcon />
                            </PrimaryButton>
                        ) : (
                            <FileUploadIcon style={{ marginRight: 10 }} onClick={pageConfig.downloadtemplate} color="primary" />
                        ))}
                    {pageConfig.filterForm &&
                        (!screenSizeDownSm ? (
                            <PrimaryButton sx={{ mr: 2 }} onClick={handleFilter} fullWidth={false}>
                                <FilterAltOutlinedIcon />
                            </PrimaryButton>
                        ) : (
                            <FilterAltIcon style={{ marginRight: 10 }} onClick={handleFilter} color="primary" />
                        ))}
                    {IsPrivilege?.includes(pageConfig.addPriviledge) &&
                        (!screenSizeDownSm ? (
                            <PrimaryButton
                                variant="contained"
                                onClick={() => pageConfig.addButtonOnClick()}
                                sx={{ color: '#ffffff', width: 'auto' }}>
                                <AddIcon />
                                &nbsp;{mobile ? '' : pageConfig.addButonText}
                            </PrimaryButton>
                        ) : (
                            <AddIcon
                                color="primary"
                                onClick={() => pageConfig.addButtonOnClick()}
                            />
                        ))}
                </Grid>
            </Grid>
            {filter &&
                Object.entries(filter).map(
                    ([key, value]: any) =>
                        value !== '' && (
                            <Chip
                                size={mobile ? 'small' : 'medium'}
                                onClick={handlefilterData}
                                key={key}
                                sx={{
                                    textTransform: 'capitalize',
                                    background: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    mr: mobile ? 1 : 2,
                                    my: mobile ? 0.5 : 1,
                                    fontSize: mobile ? '10px' : '12px',
                                    fontWeight: 700,
                                }}
                                color="error"
                                label={`${key} : ${value}`}
                            />
                        ),
                )}
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            {pageConfig.columnHeaders.map((i: any) => <TableCell>{i}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {content.map((row: any, index: number) => (
                            <Row key={row.name} row={row} rowIndex={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {(pageResponse.number > 1 ||
                pageResponse.numberOfElements > pageResponse.size - 1 ||
                pageResponse.totalPages > 1) && (
                    <TablePagination
                        count={pageResponse.totalElements}
                        rowsPerPage={pageSize}
                        page={index}
                        onRowsPerPageChange={e => {
                            pageSizeChange(e);
                        }}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                    />
                )}
            {pageConfig?.filterForm && (
                <Popover
                    onClose={() => {
                        setAnchorEl(null);
                    }}
                    sx={{ m: 4, [`& .MuiPopover-paper`]: { borderRadius: 5, p: 1, width: mobile ? '300px' : '400px' } }}
                    id={anchor_id}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    {popover}
                </Popover>
            )}
            {openDeleteModal && deleteModal}
        </>
    );
}

