import React, { FC, MouseEvent, RefObject, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Reset.scss';
import {
  AlertColor,
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Form, { FormDataModel } from '../../../components/Form';
import Notification from '../../../components/Notification/Notification';
import { ResetForm } from './ResetForm';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import { removeErrorFieldsFromValues, Validators } from '../../../utils/validators';
import axiosInstance from '../../../utils/axios';
import { URLS } from '../../../utils/constants/urls';
import InputField from '../../../components/InputField';
import { routes } from '../../../utils/constants/routes';
import { fetchFromStorage } from '../../../utils/storage';
import { identifiers } from '../../../utils/constants/identifiers';
import axios from 'axios';
import Config from '../../../utils/Config';
import { refreshToken } from '../../../utils/redux/reducer/authentication-slice';
import { useDispatch } from 'react-redux';
import strings from 'common/Translation/Translate';

const Reset: FC<any> = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation().search as any;
  const email = new URLSearchParams(params).get('email');
  const accessToken = new URLSearchParams(params).get('access-token');
  const refresh = new URLSearchParams(params).get('refresh-token') as any;
  const firstTimeLogin = new URLSearchParams(params).get('firstTimeLogin');
  const orgId = new URLSearchParams(params).get('orgId');

  const theme = useTheme();
  let resetForm: RefObject<Form | null | undefined> = useRef();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasValidCode, setHasValidCode] = useState<boolean | string>(firstTimeLogin || false);
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const handleReset = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { getFormData } = resetForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const body = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        setLoading(true);
        setCode('');
        const url = firstTimeLogin ? Config.BaseURL + URLS.resetPassword : Config.BaseURL + URLS.reset;
        const { status } = await axios.put(
          url,
          { ...body, email },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${fetchFromStorage(identifiers.access_token) || accessToken}`,
            },
          },
        );
        if (status === 200) {
          setLoading(false);
          setMessage({
            display: true,
            message: strings.password_reset_success_message,
            severity: 'success',
          });
          if (firstTimeLogin) {
            dispatch(refreshToken(refresh) as any);
            setTimeout(() => {
              if (orgId === 'null') Navigate(routes.addOrganization);
              else Navigate(routes.product);
            }, 500);
          } else {
            setTimeout(() => {
              Navigate(routes.login);
            }, 500);
          }
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setMessage({
          display: true,
          severity: 'warning',
          message: error.response.data.message,
        });
      }
      //console.log(error);
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const maxLength = { inputProps: { maxLength: 6 } };
  const handleCode = async () => {
    const sendData = {
      email,
      mailType: 'FORGOT_PASSWORD',
      code: code,
    };
    try {
      if (code) {
        setLoading(true);
        const { status } = await axiosInstance.post(URLS.verifyCode, sendData);
        if (status === 200) {
          setLoading(false);
          setMessage({
            display: true,
            message: strings.code_validated_success_message,
            severity: 'success',
          });
          setHasValidCode(true);
        }
      }
    } catch (e: any) {
      setMessage({
        display: true,
        message: `${e.response.data.message || strings.tryAgainMessage}`,
        severity: 'error',
      });
      setLoading(false);
    }
  };
  // const ResendCode = async () => {
  //     setLoading(true);
  //     try {
  //         const { status } = await axiosInstance.post(URLS.resendCode, { email });
  //         if (status === 200) {
  //             setLoading(false);
  //             setCode('');
  //             setMessage({
  //                 display: true,
  //                 message: `Code sent successfully`,
  //                 severity: "success",
  //             });
  //         }
  //     } catch (e: any) {
  //         setMessage({
  //             display: true,
  //             message: `${e.response.data.message || 'Please try again'}`,
  //             severity: "error",
  //         });
  //         setLoading(false);
  //     }

  // }
  return (
    <Container sx={matches ? {} : { px: 0 }} className={'reset-container'} maxWidth={false}>
      <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh)' }}>
        {message.display && (
          <Notification
            isOpen={message.display}
            message={message.message}
            severity={message.severity as AlertColor}
            closeNotification={closeNotification}
          />
        )}
        <Grid item xs={12} sm={8} lg={4}>
          <Box textAlign="center" mb={3}>
            <img
              style={{
                width: '100px',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
              src={identifiers.logo}
              alt="logo"
            />
          </Box>
          <Card sx={{ p: matches ? 3 : 1 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {!hasValidCode ? strings.reset_password_codePlaceholder : strings.reset_password_title}
            </Typography>

            {!hasValidCode ? (
              <>
                <Typography variant="subtitle2" marginY={2}>
                  {strings.reset_password_text1} <strong>{email}</strong> {strings.reset_password_text2}
                </Typography>
                <InputField
                  autoFocus
                  {...maxLength}
                  inputValue={code}
                  textChange={setCode}
                  label={'Enter code'}
                  validators={[
                    {
                      check: Validators.validateMandatoryField,
                      message: strings.requiredText,
                    },
                  ]}
                />
              </>
            ) : (
              <Form
                hasError={hasError}
                ref={resetForm as RefObject<Form>}
                model={ResetForm(strings, firstTimeLogin as any)}
                values={{}}
              />
            )}
            <PrimaryButton
              disabled={loading || (!hasValidCode && code.trim() === '')}
              sx={{ mt: 1, p: 1.5, color: '#ffffff' }}
              onClick={!hasValidCode ? handleCode : handleReset}>
              {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              {!hasValidCode ? 'Verify Code' : 'Reset Password'}
            </PrimaryButton>
            {/* {hasValidCode && <PrimaryButton
                            variant='text'
                            disabled={loading}
                            sx={{ mt: 1 }}
                            onClick={ResendCode}
                        >
                            Resend Code
                        </PrimaryButton>
                        } */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Reset;
