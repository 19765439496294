import addIcon from '../../assets/images/addIcon.svg';
import deleteIcon from '../../assets/images/deleteIcon.svg';
import editIcon from '../../assets/images/editIcon.svg';
import detailIcon from '../../assets/images/detailIcon.svg';
import copyIcon from '../../assets/images/copyIcon.svg';
import activeToggleIcon from '../../assets/images/activeToggleIcon.svg';
import inActiveToggleIcon from '../../assets/images/inActiveToggleIcon.svg';
import privilegeIcon from '../../assets/images/privilegeIcon.svg';
import resetPasswordIcon from '../../assets/images/resetPasswordIcon.svg';
import closeIcon from '../../assets/images/closeIcon.svg';
import menuIcon from '../../assets/images/menuIcon.svg';
import userIcon from '../../assets/images/userIcon.png';
import logoWeb from '../../assets/images/logoWeb.svg';
import sideBarOpenIcon from '../../assets/images/sideBarOpenIcon.svg';
import sideBarCloseIcon from '../../assets/images/sideBarCloseIcon.svg';
import serverDown from '../../assets/images/503.svg';
import pageNotFound from '../../assets/images/404.svg';
import uploadIcon from '../../assets/images/uploadIcon.svg';

export const images = {
  addIcon,
  serverDown,
  pageNotFound,
  deleteIcon,
  editIcon,
  detailIcon,
  copyIcon,
  activeToggleIcon,
  inActiveToggleIcon,
  privilegeIcon,
  resetPasswordIcon,
  closeIcon,
  menuIcon,
  userIcon,
  logoWeb,
  sideBarOpenIcon,
  sideBarCloseIcon,
  uploadIcon
};
