export const routes: { [key: string]: string } = {
  root: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  profile: '/profile',
  editProfile: '/profile',
  roleList: '/roles',
  addRole: '/role/add',
  editRole: '/role/editRole',
  privilige: '/role/privilige',
  product: '/product',
  coupons: '/coupons',
  orders: '/orders',
  users: '/users',
  addUser: '/users/add',

};
