import { FormModel } from 'components/Form';
import { Validators } from 'utils/validators';

export const AddEditVariantForm = (strings?: any, width = 4): FormModel[] => {
  return [
    {
      label: 'Variant name',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'name',
      validators:
        width === 12 ? [] : [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      responsive: { xs: 12 },
      required: true,
      width: width,
    },
    {
      label: 'Variant Code',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'string',
      variant: 'outlined',
      placeholder: '',
      field: 'variantCode',
      validators:
        width === 12 ? [] : [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      responsive: { xs: 12 },
      required: true,
      width: width,
    },
    {
      label: 'Price',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'number',
      variant: 'outlined',
      placeholder: '',
      field: 'price',
      validators:
        width === 12 ? [] : [{ check: Validators.validateMandatoryField }, { check: Validators.validateFloat }],
      responsive: { xs: 12 },
      required: true,
      width: width,
    },
    // {
    //   label: 'Description',
    //   value: '',
    //   size: 'medium',
    //   autoFocus: false,
    //   type: 'text',
    //   typeValue: 'text',
    //   variant: 'outlined',
    //   placeholder: '',
    //   field: 'description',
    //   validators: [],
    //   responsive: { xs: 12 },
    //   required: false,
    //   multiline: true,
    //   minRows: 1,
    //   maxRows: 5,
    //   width: width
    // },
  ];
};
