import { Button } from "@mui/material";
import React, { FC } from "react";
import { ToolTip } from "../Tooltip/ToolTip";
import "./styles.css";

type PrimaryButtonProps = {
  onClick: (e?: any) => void;
  disabled?: boolean;
  children: React.ReactNode | string;
  variant?: any;
  className?: string;
  fullWidth?: boolean;
  title?: string;
  sx?: Object;
  type?: any;
  tooltipInfo?: string;
};

const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  disabled,
  variant = "contained",
  fullWidth = true,
  className,
  children,
  title,
  type,
  sx,
  tooltipInfo,
  ...rest
}) => {
  return (
    <ToolTip title={tooltipInfo}>
      <Button
        type={type}
        title={title}
        fullWidth={fullWidth}
        sx={sx}
        // className={`${variant === "contained" ? "defaultButton" : "textButton"
        //   } ${className}`}
        data-testid="primary-button"
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        {...rest}
      >
        {children}
      </Button>
    </ToolTip>
  );
};
export default PrimaryButton;
