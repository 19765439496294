import { Validators } from '../../../../../utils/validators';
import { FormModel } from '../../../../../components/Form';
export const AddRoleForm = (strings: any, width = 4): FormModel[] => {
  return [
    {
      label: 'Role Name',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'name',
      validators:
        width === 12 ? [] : [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      responsive: width === 12 ? { xs: 12 } : { xs: 12, sm: 6, md: 4 },
      required: width === 4,
    },
  ];
};
