import strings from '../../common/Translation/Translate';
import { FormDataModel } from '../../components/Form';
import axiosInstance from '../axios';
import { PageProps } from '../constants/data';
import { clearStorage } from '../storage';

export const getFormFields: (formData: FormDataModel) => {
  [p: string]: string | number | boolean;
} = (formData: FormDataModel) => {
  const fields: { [key: string]: string | number | boolean | any } = {};
  for (const key in formData) {
    if (!key.includes('Error')) {
      fields[key] = formData[key];
    }
  }
  return fields;
};

export const removeErrorFieldsFromValues = (formData: FormDataModel) => {
  const fields: FormDataModel = {};
  for (const key in formData) {
    if (!key.includes('Error')) {
      fields[key] = formData[key];
    }
  }
  return fields;
};
export const fetchErrorFields = (formData: FormDataModel, formModel?: any) => {
  const fields = [] as any;
  for (const key in formData) {
    if (key.includes('Error') && formData[key]) {
      formModel.forEach((form: any) => {
        if (form.field === key.replace('Error', '')) {
          fields.push(form.label);
        }
      });
    }
  }
  return fields;
};

export const createDropdownData = (data: any = [], keys: any = []) => {
  const createdArray: { value: any; label: any }[] = [];
  if (data && data.length) {
    data.forEach((item: any) => {
      createdArray.push({
        value: item[keys[0]],
        label: item[keys[1]],
      });
    });
  }
  return createdArray;
};

export const formatCurrency = (amount: any) => {
  return new Intl.NumberFormat('en-In', { style: 'currency', currency: 'INR' }).format(amount);
};

/**
 * This function will extract `value` from selected multiple dropdown. This will only used when Dropdown component has isMulti: true
 * @param values - selected values from dropdown
 * @returns {[]}
 */
export const fetchIdsFromSelectedDropdownValues = (values = []) => {
  const extractedValue: any[] = [];
  if (values && values.length) {
    values.forEach((item: any) => {
      extractedValue.push(item.value);
    });
  }
  return extractedValue;
};
export const fetchLabelsFromSelectedDropdownValues = (values = []) => {
  const extractedValue: any[] = [];
  if (values && values.length) {
    values.forEach((item: any) => {
      extractedValue.push(item.label);
    });
  }
  return extractedValue.join(',');
};
export const logoutUser = () => {
  clearStorage('all');
  window.location.href = '#/login';
};
export const downloadExportFile = (response: any, type: string, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([response.data || response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('target', `_blank`);
  link.setAttribute('download', `${fileName || 'Export'}.${type}`);
  document.body.appendChild(link);
  link.click();
};

export const ScrollTop = (x: number) => {
  return window.scrollTo({
    top: x,
    behavior: 'smooth',
  });
};


export const getListData = async (setLoading: any, filterData: PageProps, listApi: any, setPageResponse: any) => {
  try {
    setLoading(true);
    const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
    const { data } = await axiosInstance.post(listApi, body);
    setLoading(false);
    setPageResponse(data);
  } catch (error: any) {
    setLoading(false);
  }
};

export const getPageDataWithLanguage = async (languageList: any, getByIdApi: any, id: any, setPageResponse: any) => {
  try {
    const { data } = await axiosInstance.get(getByIdApi(id));
    const container: any = {};
    for (let i = 0; i < languageList?.length; ++i) {
      let values = ['name_' + languageList[i]?.value];
      container[values as any] = data.localizedAttributes[languageList[i]?.value]
        ? data.localizedAttributes[languageList[i]?.value].name
        : '';
    }
    setPageResponse(container);
  } catch (error: any) {}
};

export const getDrawerWidth = (screenSizeDownSm: any, screenSizeDownMd: any, screenSizeUpLg?: any) => {
  if (screenSizeDownSm) return '100%';
  else if (screenSizeDownMd) return '70%';
  else if (screenSizeUpLg) return '30%';
  else return '50%';
};


export const getLineItemDrawerWidth = (screenSizeDownSm: any, screenSizeDownMd: any, screenSizeUpLg?: any) => {
  if (screenSizeDownSm) return '100%';
  else if (screenSizeDownMd) return '80%';
  else return '70%';
};
