import React, { FC, useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { AlertColor, Container, FormControlLabel, FormGroup, Grid } from '@mui/material';
import axiosInstance from '../../../../utils/axios';
import { URLS } from '../../../../utils/constants/urls';
import Notification from '../../../../components/Notification';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../../../../components/Button/PrimaryButton';
import Checkbox from '@mui/material/Checkbox';
import './role.scss';
import { useDispatch } from 'react-redux';
import { routes } from '../../../../utils/constants/routes';
import strings from '../../../../common/Translation/Translate';
import PageLoader from '../../../../components/PageLoader';
import { refreshToken } from '../../../../utils/redux/reducer/authentication-slice';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} children={props.children} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const Roles = [{ id: 1, name: 'USERS' }];

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Privilege: FC = () => {
  const { id } = useParams() as any;
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<any>('Bookings');
  const [pageResponse, setPageResponse] = useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [selectAll, setSelectAll] = useState<any>(false);
  const [selectedValue, setSelectedValue] = useState<any>([]);
  const [roleName, setRoleName] = useState<any>([]);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getPrivileges = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.editRole(id));
      setLoading(false);
      const dataObject = data.privileges;
      setRoleName(data.name);
      let allcheck = null;
      const array = [...selectedValue];
      if (dataObject) {
        for (let i = 0; i < dataObject.length; i++) {
          for (let j = 0; j < dataObject[i].privilegesInGroup.length; j++) {
            if (dataObject[i].privilegesInGroup[j].selected === true) {
              array.push(dataObject[i].privilegesInGroup[j].id);
            }
          }
          if (allcheck !== false) {
            allcheck = dataObject[i].privilegesInGroup.every((item: any) => item.selected);
          }
        }
        setSelectAll(allcheck);
        setSelectedValue(array);
        setPageResponse(dataObject);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setMessage({
          display: true,
          severity: 'warning',
          message: error.response.data.message,
        });
      }
    }
  }, [id, selectedValue]);

  useEffect(() => {
    getPrivileges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };
  const handleInputChangeHelper1 = (checked: any, dataObject: any) => {
    setSelectAll(checked);
    for (let i = 0; i < dataObject.length; i++) {
      for (let j = 0; j < dataObject[i].privilegesInGroup.length; j++) {
        dataObject[i].privilegesInGroup[j].selected = checked;
      }
    }
  };
  const handleInputChangeHelper2 = (checked: any, dataObject: any, id: number) => {
    let allChecked = null;
    for (let i = 0; i < dataObject.length; i++) {
      for (let j = 0; j < dataObject[i].privilegesInGroup.length; j++) {
        if (dataObject[i].privilegesInGroup[j].id === id) {
          dataObject[i].privilegesInGroup[j].selected = checked;
        }
      }
      if (allChecked !== false) {
        allChecked = dataObject[i].privilegesInGroup.every((item: any) => item.selected);
      }
    }
    setSelectAll(allChecked);
    return dataObject;
  };

  const handleInputChangeHelper3 = (dataObject: any) => {
    const arrayObj = [...dataObject];
    const array = [];
    for (let i = 0; i < arrayObj.length; i++) {
      for (let j = 0; j < arrayObj[i].privilegesInGroup.length; j++) {
        if (arrayObj[i].privilegesInGroup[j].selected === true) {
          array.push(arrayObj[i].privilegesInGroup[j].id);
        }
      }
    }
    return array;
  };
  const handleInputChange = (e: any, id: any) => {
    const itemName = e.target.name;
    const { checked } = e.target;
    let dataObject = pageResponse;
    if (itemName === 'checkAll') {
      handleInputChangeHelper1(checked, dataObject);
    } else {
      dataObject = handleInputChangeHelper2(checked, dataObject, id);
    }
    const array = handleInputChangeHelper3(dataObject);
    setSelectedValue(array);
    setPageResponse(dataObject);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const jsonData = {
        name: roleName,
        privilegeIds: selectedValue,
      };
      const { status } = await axiosInstance.put(URLS.updatePriviliges(id), jsonData);
      if (status === 200) {
        setLoading(false);
        setMessage({
          display: true,
          message: strings.privilege_success_message,
          severity: 'success',
        });
        setTimeout(() => {
          Navigate(routes.roleList);
        }, 1000);
        await dispatch(refreshToken() as any);
      }
    } catch (e: any) {
      setMessage({
        display: true,
        message: `${e.response.data.message || 'Please try again'}`,
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <Container sx={{ p: '1rem' }} maxWidth={false} className="privileges">
      {loading && <PageLoader />}
      <Grid item md={12}>
        <Grid justifyContent="space-between" container>
          <Typography sx={{ fontSize: 24, fontWeight: 500 }} mb={2}>
            {strings.customermgmt_column_role} ({roleName})
          </Typography>
          <Grid item>
            <PrimaryButton variant="contained" onClick={handleSave} fullWidth={false}>
              {strings.common_button_save}
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3}>
        <FormGroup sx={{ ml: 2 }}>
          <FormControlLabel
            control={
              <Checkbox onChange={e => handleInputChange(e, null)} checked={selectAll} name="checkAll" {...label} />
            }
            label={strings.privilege_select_all_text}
          />
        </FormGroup>
      </Grid>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      {pageResponse.map((item: any, key: number) => (
        <Accordion
          sx={{ mb: 1 }}
          key={item.groupName}
          expanded={expanded === item.groupName}
          onChange={handleChange(item.groupName)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{item.groupName}</Typography>
          </AccordionSummary>
          {item?.privilegesInGroup?.length > 0 &&
            item?.privilegesInGroup
              // ?.filter((filter: any) => filter.groupName === item.name)
              .map((privilege: any) => (
                <AccordionDetails key={privilege.id}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={privilege.name}
                          onChange={e => handleInputChange(e, privilege.id)}
                          name={privilege.name}
                          key={privilege.id}
                          {...label}
                          // defaultChecked={privilege.selected}
                          checked={privilege.selected}
                        />
                      }
                      label={privilege.name}
                    />
                  </FormGroup>
                </AccordionDetails>
              ))}
        </Accordion>
      ))}
    </Container>
  );
};

export default Privilege;
