import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface NavigationProps {
  url?: any;
  aria?: string
}
const Navigation: FC<NavigationProps> = ({ url, aria }) => {
  const Navigate = useNavigate();
  return (
    <IconButton onClick={() => Navigate(url)} aria-label={aria || 'icon'}>
      <Close />
    </IconButton>)
};

export default Navigation;