import { Validators } from '../../../utils/validators';
import { FormModel } from '../../../components/Form';

export const ChangePasswordForm = (strings: any): FormModel[] => {
  return [
    {
      label: 'Old Password',
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'password',
      variant: 'outlined',
      placeholder: '',
      field: 'oldPassword',
      validators: [{ check: Validators.validateMandatoryField }],
      responsive: { xs: 12 },
      required: true,
    },
    {
      label: 'New Password',
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'password',
      variant: 'outlined',
      placeholder: '',
      field: 'newPassword',
      validators: [{ check: Validators.validateMandatoryField }, { check: Validators.validatePassword }],
      responsive: { xs: 12 },
      required: true,
    },
    {
      label: 'Confirm Password',
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'password',
      variant: 'outlined',
      placeholder: '',
      field: 'confirmPassword',
      validators: [{ check: Validators.validateMandatoryField }],
      responsive: { xs: 12 },
      required: true,
    },
  ];
};
