import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import PrimaryButton from '../Button/PrimaryButton';

const Modal = ({
  open,
  setModalClose,
  title,
  size,
  isBackdrop = false,
  className,
  closeButton = true,
  contentTitle,
  contentText,
  backButtonText,
  backButtonFunc,
  saveButtonText,
  saveButtonFunc,
  loading,
  ...rest
}) => {
  const handleClose = () => {
    setModalClose(false);
  };
  return (
    <Dialog
      className={className}
      {...rest}
      fullWidth
      maxWidth={size || 'xs'}
      open={open}
      onClose={isBackdrop ? () => {} : handleClose}>
      <Grid container>
        <Grid item xs={10}>
          {title && <DialogTitle>{title}</DialogTitle>}
        </Grid>
        <Grid item xs={2} textAlign={'right'}>
          {closeButton && (
            <div className="closeButton">
              <IconButton size="small" color="default" className="deleteButton" onClick={handleClose} aria-label="edit">
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          )}
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                mb: 1,
                fontSize: 24,
                fontWeight: 600,
                color: '#333333',
              }}>
              {contentTitle}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 5,
                fontSize: 12,
                fontWeight: 400,
                color: '#727272',
              }}>
              {contentText}
            </Typography>
          </Grid>
          <Grid item xs={6} className={'pt-0'}>
            <PrimaryButton variant="outlined" onClick={backButtonFunc}>
              {backButtonText}
            </PrimaryButton>
          </Grid>
          <Grid item xs={6} className={'pt-0'}>
            <PrimaryButton disabled={loading} onClick={saveButtonFunc}>
              {/* {loading && <CircularProgress sx={{ mr: 1 }} size={20} />} */} {saveButtonText}
            </PrimaryButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
