import { FC, useEffect, useState, useCallback } from 'react';
import {
  AlertColor,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PageLoader from '../../../components/PageLoader';
import { useSelector } from 'react-redux';
import { Privilege } from '../../../utils/redux/reducer/authentication-slice';
import strings from 'common/Translation/Translate';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/axios';
import ListPage from '../CommonPage';
import { privileges } from 'utils/constants/privileges';
import DrawerMenu from 'components/DrawerMenu';
import { FormComponent } from 'utils/validators/HelperComponents';
import { getDrawerWidth } from 'utils/validators/HelperFunctions';
import Upload from 'components/Upload/Upload';
import PrimaryButton from 'components/Button/PrimaryButton';
import Notification from 'components/Notification';
import { CouponFilterForm } from './FilterForms/CouponFilterForm';

const CouponList = ({ id }: { id: any }) => {
  const theme = useTheme();
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [pageResponse, setPageResponse] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };


  const pageConfig = {
    title: strings.menu_text_coupon,
    listApi: URLS.couponList(id),
    // uploadPrivilege: privileges.import_coupon,
    // uploadButtonText: strings.ImportCouponsText,
    // uploadFunc: () => setOpenDrawer(true),
    deleteApi: URLS.deleteCoupon,
    deletePrivilege: privileges.import_coupon,//privileges.delete_coupon,
    tableColumn: [
      { field: 'couponCode', headerName: 'Coupon Code', flex: 1, sortable: true },
      { field: 'status', headerName: 'Status', flex: 1, sortable: true },
      { field: 'name', headerName: 'Name', flex: 1, sortable: true },
      { field: 'loyaltyPoints', headerName: 'Loyalty Points', flex: 1, sortable: true },
      { field: 'price', headerName: 'Price', flex: 1, sortable: true },
      { field: 'productCode', headerName: 'Product Code', flex: 1, sortable: true },
      { field: 'productName', headerName: 'Product Name', flex: 1, sortable: true },
    ],
    isActionCol: true,
    filterForm: CouponFilterForm(strings, 12),
  };

  const getList = useCallback(
    async (filterData: any) => {
      try {
        setLoading(true);
        const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
        const { data } = await axiosInstance.post(pageConfig.listApi, body);
        const customContent = data.content.map((item: any) => ({
          id: item.id,
          couponCode: item.couponCode,
          status: item.status,
          name: item.variant.name,
          loyaltyPoints: item.variant.loyaltyPoints,
          price: item.variant.price,
          productCode: item.variant.product.productCode,
          productName: item.variant.product.name,
        }))
        const customData = { ...data, content: customContent }
        setPageResponse(customData);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [pageConfig.listApi],
  );

  useEffect(() => {
    (async () => {
      await getList(null);
    })();
  }, [getList]);



  return (<>
    {message.display && (
      <Notification
        isOpen={message.display}
        message={message.message}
        severity={message.severity as AlertColor}
        closeNotification={closeNotification}
      />
    )}
    <ListPage loading={loading} pageConfig={pageConfig} pageResponse={pageResponse} getList={getList} isWhiteSpace={true} />

  </>)
};
export default CouponList;
