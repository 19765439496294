import PageLoader from 'components/PageLoader';
import { useCallback, useEffect, useState } from 'react';
import strings from '../../../../../common/Translation/Translate';
import axiosInstance from '../../../../../utils/axios';
import { URLS } from '../../../../../utils/constants/urls';
import AddEdit from '../../../CommonPage/AddEdit';
import { AddEditVariantForm } from './AddEditVariantForm';

const AddEditVariant = ({
  productId,
  id,
  handleDrawerClose,
  getList,
}: {
  productId: any;
  id: any;
  handleDrawerClose: any;
  getList: any;
}) => {
  //   const { id } = useParams() as any;
  const [pageResponse, setPageResponse] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getInitialResponse = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.variantListById(productId, id));
      setPageResponse(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getInitialResponse();
      }
    })();
  }, [getInitialResponse, id]);

  const pageConfig = {
    title: id ? 'Edit Variant' : 'Add Variant',
    successMessage: id ? strings.common_update_message : strings.common_save_message,
    addEditApiUrl: id ? URLS.variantListById(productId, id) : URLS.variantAdd(productId),
    initialApi: URLS.variantListById(productId, id),
    formModel: AddEditVariantForm(strings),
    isCustomResponse: true,
  };

  return (
    <>
      {loading && <PageLoader />}
      <AddEdit
        pageConfig={pageConfig}
        pageResponse={pageResponse}
        id={id}
        handleDrawerClose={handleDrawerClose}
        getList={getList}
      />
    </>
  );
};
export default AddEditVariant;
