import React, { FC, useCallback, useEffect, useState } from 'react';
import { routes } from '../../../../utils/constants/routes';
import ListPage from '../../CommonPage';
import { URLS } from '../../../../utils/constants/urls';
import { privileges } from '../../../../utils/constants/privileges';
import axiosInstance from '../../../../utils/axios';
import strings from '../../../../common/Translation/Translate';
import { images } from '../../../../utils/constants/images';
import { useNavigate } from 'react-router-dom';
import { AddRoleForm } from './AddEditRole/AddRoleForm';

export const RoleList: FC = () => {
  const navigate = useNavigate();
  const [pageResponse, setPageResponse] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const handleCustomImageMethod = (id: number) => {
    //console.log(id);
    navigate(routes.privilige + '/' + id);
  };
  const pageConfig = {
    title: strings.rolesmgmt_title_roles,
    AddButtonText: strings.rolesmgmt_button_add,
    AddButtonUrl: routes.addRole,
    editButtonUrl: routes.editRole,
    listApi: URLS.rolePage,
    deleteApi: URLS.deleteRole,
    addPrivilege: privileges.add_role,
    editPrivilege: privileges.edit_role,
    deletePrivilege: privileges.delete_role,
    customImage: {
      imageUrl: images.privilegeIcon,
      handleCustomImageMethod,
      privilege: privileges.edit_privileges,
    },
    tableColumn: [{ field: 'name', headerName: strings.rolesmgmt_column_name, flex: 6, sortable: true }],
    filterForm: AddRoleForm(strings, 12),
    isActionCol: true
  };

  const getList = useCallback(
    async (filterData?: any) => {
      try {
        setLoading(true);
        const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
        const { data } = await axiosInstance.post(pageConfig.listApi, body);
        setPageResponse(data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [pageConfig.listApi],
  );

  useEffect(() => {
    (async () => {
      await getList(null);
    })();
  }, [getList]);

  return <ListPage loading={loading} pageConfig={pageConfig} pageResponse={pageResponse} getList={getList} />;
};
