import { FC, useEffect, useState, useCallback } from 'react';
import {
  AlertColor,
  Box,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PageLoader from '../../../components/PageLoader';
import { useSelector } from 'react-redux';
import { Privilege } from '../../../utils/redux/reducer/authentication-slice';
import strings from 'common/Translation/Translate';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/axios';
import { privileges } from 'utils/constants/privileges';
import Notification from 'components/Notification';
import Typography from '@mui/material/Typography';
import { DataTable } from '../Product/DataTable';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VariantDataTable from './VariantDataTable';
import { ProductFilterFormCoupon } from './FilterForms/ProductFilterFormCoupon';

const Coupon: FC = () => {
  const theme = useTheme();
  const IsPrivilege = useSelector(Privilege);
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [productListData, setProductListData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const getProductListFunc = useCallback(
    async (filterData: any) => {
      try {
        setLoading(true);
        const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
        const { data } = await axiosInstance.post(URLS.productList, body);
        setProductListData(data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [URLS.productList],
  );

  useEffect(() => {
    (async () => {
      await getProductListFunc(null);
    })();
  }, [getProductListFunc]);

  const ProductTablePageConfig = {
    header: 'Products',
    columnHeaders: [''],
    isExpandable: true,
    innerComponentPrivilege: privileges.view_variant,
    hideTableHead: true,
    filterForm: ProductFilterFormCoupon(strings, 12)
  }

  const ProductRow = ({ row, open, setOpen, deleteUser, setOuterRowId }: { row: any, open: any, setOpen: any, deleteUser: any, setOuterRowId: any }) =>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      {IsPrivilege?.includes(privileges.view_variant) && (<TableCell>
        <Box onClick={() => { setOpen(!open); setOuterRowId(row.id) }}>
          {open ?
            <Box display={'flex'} alignItems={'center'} gap={3}>
              <KeyboardArrowUpIcon />
              <Typography>{row.name} - {row.productCode}</Typography>
            </Box>
            :
            <Box display={'flex'} alignItems={'center'} gap={3}>
              <KeyboardArrowDownIcon />
              <Typography>{row.name} - {row.productCode}</Typography>
            </Box>}
        </Box>
      </TableCell>)}
    </TableRow>

  return (<>
    {loading && <PageLoader />}
    {message.display && (
      <Notification
        isOpen={message.display}
        message={message.message}
        severity={message.severity as AlertColor}
        closeNotification={closeNotification}
      />
    )}
    {Object.keys(productListData).length > 0 &&
      <DataTable
        pageResponse={productListData}
        getList={getProductListFunc}
        pageConfig={ProductTablePageConfig}
        outerChildren={
          (row: any, open: any, setOpen: any, deleteUser: any, setOuterRowId: any) =>
            <ProductRow row={row} open={open} setOpen={setOpen} deleteUser={deleteUser} setOuterRowId={setOuterRowId} />}
        innerChildren={(outerRowId: any) => <VariantDataTable productId={outerRowId} />
        }
      />}
   
  </>)
};
export default Coupon;
