import { AlertColor, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material';
import strings from 'common/Translation/Translate';
import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { identifiers } from 'utils/constants/identifiers';
import { fetchFromStorage } from 'utils/storage';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import Form, { FormDataModel } from '../../../components/Form';
import Notification from '../../../components/Notification';
import axiosInstance from '../../../utils/axios';
import { URLS } from '../../../utils/constants/urls';
import Navigation from '../../../utils/hooks/Navigation';
import { removeErrorFieldsFromValues } from '../../../utils/validators';
import { EditProfileForm } from './EditProfileForm';
import './UserProfile.scss';

const EditProfile = () => {
  let editProfileForm: RefObject<Form | null | undefined> = useRef();
  const [userData, setUserData] = useState<any>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const getUser = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.userProfile);
      setUserData(data);
    } catch (error: any) {
      if (error.response) {
        setMessage({
          display: true,
          severity: 'warning',
          message: error.response.data.message,
        });
      }
      //console.log(error);
    }
  }, []);
  
  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const update = async () => {
    try {
      setHasError(false);
      const { getFormData } = editProfileForm.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData, isFormValid } = getFormData();
      //console.log('editProfile: ', formData, isFormValid)
      if (isFormValid) {
        setLoading(true);
        const body = removeErrorFieldsFromValues(formData);
        // const city = cities.find((el: any) => el.value === body.city);
        // const countries = country.find((el: any) => el.value === body.country);
        // const state = states.find((el: any) => el.value === body.state);
        const { status } = await axiosInstance.put(URLS.userProfile, {
          ...body
        });
        if (status === 200) {
          setLoading(false);
          setMessage({
            display: true,
            severity: 'success',
            message: strings.profile_reset_success_message,
          });
          await getUser();
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setMessage({
          display: true,
          severity: 'warning',
          message: error.response.data.message,
        });
      }
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };
  const handleChange = (key: string, value: any, formdata: any) => { 
   
      setUserData({ ...userData, [key]: value });
    
  };

  return (
    <Container maxWidth={false} sx={{ mb: 4 }}>
      {!!userData && (
        <>
          <Grid container spacing={2}>
            {message.display && (
              <Notification
                isOpen={message.display}
                message={message.message}
                severity={message.severity as AlertColor}
                closeNotification={closeNotification}
              />
            )}
            <Grid item xs={10}>
              <Typography sx={{ fontSize: 24, fontWeight: 500 }}>{strings.updateProfile_title_text}</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2 }} />
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Form
              onChange={handleChange}
              hasError={hasError}
              ref={editProfileForm as RefObject<Form>}
              values={userData}
              model={EditProfileForm(strings)}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <PrimaryButton
              disabled={loading}
              onClick={update}
              variant={'contained'}
              fullWidth
              sx={{ color: '#ffffff' }}>
              {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              {strings.userprofile_button_updateProfile}
            </PrimaryButton>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default EditProfile;
