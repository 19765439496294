import axiosInstance from "utils/axios";
import { URLS } from "utils/constants/urls";

export const getRoles = async () => {
    try {
        const { status, data } = await axiosInstance.get(URLS.role);
        if (status === 200) {
            return data;
        }
    } catch (e) {
        console.error(e)
        throw e;
    }
};

