import { Validators } from '../../../utils/validators';
import { FormModel } from '../../../components/Form';

export const EditProfileForm = (
  strings: any
): FormModel[] => {
  return [
    {
      label: strings.updateProfile_text_firstName,
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'firstName',
      validators: [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      responsive: { xs: 12, sm: 6, md: 4, lg: 3 },
      required: true,
    },
    {
      label: strings.updateProfile_text_lastName,
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'lastName',
      validators: [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      responsive: { xs: 12, sm: 6, md: 4, lg: 3 },
      required: true,
    },
    {
      label: strings.updateProfile_text_phoneNumber,
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'phone',
      validators: [{ check: Validators.validateMandatoryField }, { check: Validators.validateMobile }],
      responsive: { xs: 12, sm: 6, md: 4, lg: 3 },
      required: true,
      disabled: false,
    },
    {
      label: strings.updateProfile_text_email,
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'email',
      validators: [
        { check: Validators.validateMandatoryField },
        { check: Validators.validateEmail },
        { check: Validators.validateString },
      ],
      responsive: { xs: 12, sm: 6, md: 4, lg: 3 },
      required: true,
      disabled: true,
    },
    
  ];
};
