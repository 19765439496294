import { FormModel } from 'components/Form';
import { Validators } from 'utils/validators';

export const AddProductsForm = (strings?: any, width = 4): FormModel[] => {
  return [
    {
      label: 'Product name',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'name',
      validators:
        width === 12 ? [] : [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      responsive: { xs: 12 },
      required: true,
    },
    {
      label: 'Product code',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'productCode',
      validators:
        width === 12 ? [] : [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      responsive: { xs: 12 },
      required: true,
    },
    {
      label: 'Brand',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'brand',
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
    {
      label: 'Category',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'category',
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
    {
      label: 'Max Count Per Txn',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'number',
      variant: 'outlined',
      placeholder: '',
      field: 'maxCountPerTxn',
      validators: width === 12 ? [] : [{ check: Validators.validateMandatoryField }, { check: Validators.validateInt }],
      responsive: { xs: 12 },
      required: true,
    },
    {
      label: 'Description',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'description',
      validators: [],
      responsive: { xs: 12 },
      required: false,
      multiline: true,
      minRows: 1,
      maxRows: 5,
    },
    {
      label: 'How to use',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'howToUse',
      validators: [],
      responsive: { xs: 12 },
      required: false,
      multiline: true,
      minRows: 1,
      maxRows: 5,
    },
    {
      label: 'Terms & Conditions',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'terms',
      validators: [],
      responsive: { xs: 12 },
      required: false,
      multiline: true,
      minRows: 1,
      maxRows: 5,
    },
    // {
    //   label: 'Image Url',
    //   value: '',
    //   size: 'medium',
    //   autoFocus: false,
    //   type: 'text',
    //   typeValue: 'text',
    //   variant: 'outlined',
    //   placeholder: '',
    //   field: 'image',
    //   validators: [],
    //   responsive: { xs: 12 },
    //   required: false,
    // },
    {
      label: 'Upload Image',
      value: '',
      type: 'upload',
      placeholder: '',
      field: 'image',
      accept: 'image/png, image/gif, image/jpeg',
      responsive: { xs: 12 },
      validators: width === 12 ? [] : [{ check: Validators.validateMandatoryField }],
      required: true,
      multiline: false,
    },
  ];
};
