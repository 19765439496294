import { FC, useEffect, useState, useCallback } from 'react';
import {
  AlertColor,
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PageLoader from '../../../components/PageLoader';
import { useSelector } from 'react-redux';
import strings from 'common/Translation/Translate';
import { URLS } from 'utils/constants/urls';
import axiosInstance from 'utils/axios';
import { privileges } from 'utils/constants/privileges';
import DrawerMenu from 'components/DrawerMenu';
import { downloadExportFile, getDrawerWidth } from 'utils/validators/HelperFunctions';
import Notification from 'components/Notification';
import { DataTable } from './DataTable';
import { ToolTip } from 'components/Tooltip/ToolTip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VariantList from './Variant/VariantList';
import AddEditProducts from './AddEditProduct/AddEditProduct';
import { Privilege } from 'utils/redux/reducer/authentication-slice';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { ProductFilterForm } from './AddEditProduct/ProductFilterForm';
import axios from 'axios';
import Config from 'utils/Config';
import { identifiers } from 'utils/constants/identifiers';
import { fetchFromStorage } from 'utils/storage';
import PrimaryButton from 'components/Button/PrimaryButton';
import Upload from 'components/Upload/Upload';
import { images } from 'utils/constants/images';

const Product: FC = () => {
  const theme = useTheme();
  const token = fetchFromStorage(identifiers.access_token) as any;
  const IsPrivilege = useSelector(Privilege);
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screenSizeUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [pageResponse, setPageResponse] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [id, setId] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [deletedImage, setDeletedImage] = useState<any>([]);
  const [openUploadDrawer, setOpenUploadDrawer] = useState<boolean>(false);
  const [uploadDetails, setUploadDetails] = useState<any>({ title: '', url: '' });
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const getFileDetails = (
    value: string,
    field: string,
    error?: { error: boolean; message: string },
    isDeleted?: any,
  ) => {
    const deletedArray = [...deletedImage];
    if (Array.isArray(isDeleted)) {
      deletedArray.push(isDeleted[0].name);
      setDeletedImage(deletedArray);
      setDeletedImage([]);
      setUploadedFile(value);
    } else {
      setUploadedFile(value);
    }
  };

  const handleUploadTemplate = async (url: any) => {
    try {
      if (uploadedFile === null) {
        setMessage({
          display: true,
          severity: 'error',
          message: 'Please upload a file',
        });
      }
      else {
        setLoading(true);
        const body = new FormData();
        uploadedFile.forEach((file: any) => {
          body.append('file', file);
        });
        body.append('deletedFiles', deletedImage);
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const { status } = await axiosInstance.post(url, body, config);
        if (status === 200) {
          setOpenUploadDrawer(false);
          setUploadedFile(null);
          setMessage({
            display: true,
            severity: 'success',
            message: 'File uploaded successfully',
          });
          await getList(null)
        }
        setLoading(false);
      }

    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'error',
        message: error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data.message,
      });
    }
  }
  const getList = useCallback(
    async (filterData: any) => {
      try {
        setLoading(true);
        const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
        const { data } = await axiosInstance.post(URLS.productList, body);
        setPageResponse(data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [URLS.productList],
  );

  useEffect(() => {
    (async () => {
      await getList(null);
    })();
  }, [getList]);


  const ProductRow = ({ row, open, setOpen, deleteUser, setOuterRowId }: { row: any, open: any, setOpen: any, deleteUser: any, setOuterRowId: any }) =>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      {IsPrivilege?.includes(privileges.view_variant) && (<TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => { setOpen(!open); setOuterRowId(row.id) }}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>)}
      <TableCell >
        {row.image && <img
          src={row.image}
          alt=''
          height='50px'
          width='65px'
        />
        }
      </TableCell>
      <TableCell >{row.name}</TableCell>
      <TableCell >{row.productCode}</TableCell>
      <TableCell >
        <ToolTip title={row.description}>
          {row.description?.length > 50 ?
            <Typography>{row.description.substring(0, 50)}<b>...</b></Typography>
            : <Typography>{row.description}</Typography>
          }
        </ToolTip>
      </TableCell>
      <TableCell>
        <Box display='flex' alignItems='center'>
          {IsPrivilege?.includes(privileges.import_variant) && (
            <ToolTip title={'Upload variants'}>
              <img
                className={'cursor-pointer'}
                src={images.uploadIcon}
                alt="reset"
                width="20px"
                height="20px"
                onClick={() => {
                  setUploadDetails({ title: 'Upload Variants', url: URLS.uploadVariant(row.id) })
                  setOpenUploadDrawer(true)
                }
                }
              />
            </ToolTip>
          )}
          {IsPrivilege?.includes(privileges.edit_product) && (
            <ToolTip title={strings.editText}>
              <IconButton onClick={() => { setOpenDrawer(true); setId(row.id) }} aria-label="theme">
                <EditIcon fontSize="small" color='primary' />
              </IconButton>
            </ToolTip>
          )}
          {IsPrivilege?.includes(privileges.delete_product) && (
            <ToolTip title={strings.deleteText}>
              <IconButton onClick={() => deleteUser(row.id)} aria-label="theme">
                <DeleteOutlineOutlinedIcon fontSize="small" color='primary' />
              </IconButton>
            </ToolTip>
          )}
        </Box>
      </TableCell>
    </TableRow>

  const handleDownloadHelper = (e: any, url: string) => {
    e.preventDefault();
    try {
      setLoading(true);
      axios({
        url: `${Config.BaseURL + url}`,
        method: 'get',
        data: {},
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })
        .then((response: any) => {
          setLoading(false);
          downloadExportFile(response, 'csv', `template`);
        })
        .catch(err => {
          setLoading(false);
        });
    } catch (error: any) {
      setLoading(false);
      setMessage({
        display: true,
        severity: 'warning',
        message: error.response.data.message,
      });
    }
  }

  const ProductTablePageConfig = {
    header: 'Products',
    addButonText: 'Add New Product',
    addPriviledge: privileges.add_product,
    columnHeaders: IsPrivilege?.includes(privileges.view_variant) ?
      ['', '', 'Product Name', 'Product Code', 'Description', 'Actions']
      : ['', 'Product Name', 'Product Code', 'Description', 'Actions'],
    isExpandable: true,
    addButtonOnClick: () => { setOpenDrawer(true); setId(null) },
    deleteApi: URLS.productListById,
    innerComponentPrivilege: privileges.view_variant,
    filterForm: ProductFilterForm(strings, 12),
    downloadtemplate: () => setOpenModal(true),
    handleUpload: () => {
      setUploadDetails({ title: 'Upload Products', url: URLS.uploadProduct })
      setOpenUploadDrawer(true)
    }
  }

  const handleDrawerClose = () => setOpenDrawer(false)

  return (
    <>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      {Object.keys(pageResponse).length > 0 &&
        <DataTable
          pageResponse={pageResponse}
          getList={getList}
          pageConfig={ProductTablePageConfig}
          outerChildren={
            (row: any, open: any, setOpen: any, deleteUser: any, setOuterRowId: any) =>
              <ProductRow row={row} open={open} setOpen={setOpen} deleteUser={deleteUser} setOuterRowId={setOuterRowId} />}
          innerChildren={(outerRowId: any) => <VariantList productId={outerRowId} />
          }
        />}
      <DrawerMenu
        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screenSizeUpLg)}
        open={openDrawer}
        handleClose={handleDrawerClose}
        closeButton={true}
        title={id ? 'Edit Product' : 'Add Product'}>
        <AddEditProducts id={id} handleDrawerClose={handleDrawerClose} getList={getList} />
      </DrawerMenu>
      <DrawerMenu
        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screenSizeUpLg)}
        open={openUploadDrawer}
        handleClose={() => setOpenUploadDrawer(false)}
        closeButton={true}
        title={uploadDetails.title}>
        <Upload inputValue={uploadedFile} textChange={getFileDetails} accept={'text/csv'} />
        <PrimaryButton onClick={() => handleUploadTemplate(uploadDetails.url)} sx={{ width: '30%' }}>
          Upload
        </PrimaryButton>
      </DrawerMenu>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 3,
        }}>
          {(IsPrivilege?.includes(privileges.import_product)
            || IsPrivilege?.includes(privileges.import_variant)
            || IsPrivilege?.includes(privileges.import_coupon)
          ) &&
            <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
              <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                Download Template
              </Typography>
              <IconButton size="small" color="default" className="deleteButton" onClick={() => setOpenModal(false)} aria-label="edit">
                <ClearOutlinedIcon />
              </IconButton>
            </Box>
          }
          {(IsPrivilege?.includes(privileges.import_product)) &&
            <Box display={'flex'} alignItems={'center'} gap={1} onClick={(e: any) => handleDownloadHelper(e, URLS.downloadProductTemplate)} sx={{ cursor: 'pointer', mb: 1 }}>
              <Typography>Download Product Template</Typography>
              <CloudDownloadIcon />
            </Box>
          }
          {(IsPrivilege?.includes(privileges.import_variant)) &&
            <Box display={'flex'} alignItems={'center'} gap={1} onClick={(e: any) => handleDownloadHelper(e, URLS.downloadVariantTemplate)} sx={{ cursor: 'pointer', mb: 1 }}>
              <Typography>Download Variants Template</Typography>
              <CloudDownloadIcon />
            </Box>
          }
          {IsPrivilege?.includes(privileges.import_coupon) &&
            <Box display={'flex'} alignItems={'center'} gap={1} onClick={(e: any) => handleDownloadHelper(e, URLS.downloadCouponsTemplate)} sx={{ cursor: 'pointer', mb: 1 }}>
              <Typography>Download Coupons Template</Typography>
              <CloudDownloadIcon />
            </Box>
          }
        </Box>
      </Modal>
    </>
  )
};

export default Product;

