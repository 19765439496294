import { useState, RefObject, useRef, MouseEvent } from 'react';
import { AlertColor, Button, CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import strings from '../../../common/Translation/Translate';
import axiosInstance from '../../../utils/axios';
import { URLS } from '../../../utils/constants/urls';
import Notification from '../../../components/Notification';
import Form, { FormDataModel } from '../../../components/Form';
import DrawerMenu from '../../../components/DrawerMenu';
import { removeErrorFieldsFromValues } from '../../../utils/validators';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import { ChangePasswordForm } from './ChangePasswordForm';
import { refreshToken } from '../../../utils/redux/reducer/authentication-slice';
import { useDispatch } from 'react-redux';
import { getDrawerWidth } from 'utils/validators/HelperFunctions';

type ChangePasswordFormComponentProps = {
  hasError: any,
  formRef: any,
  isLoading: any,
  handleSave: any,
  theme: any
}


const ChangePasswordFormComponent = ({ hasError, formRef, isLoading, handleSave, theme }: ChangePasswordFormComponentProps) => {
  return (
    <>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Form
          hasError={hasError}
          ref={formRef as RefObject<Form>}
          model={ChangePasswordForm(strings)}
          values={{}}
        />
      </Grid>
      <Grid item sx={{ my: 1 }} xs={12} sm={3} lg={2}>
        <Button disabled={isLoading} variant="contained" sx={{ color: "#ffffff" }}
          fullWidth
          onClick={(e) => handleSave(e)}>
          {isLoading && <CircularProgress size={20} sx={{ mr: 1, color: theme.palette.primary.contrastText }} />}
          {strings.common_button_save}
        </Button>
      </Grid>
    </>)
}

type ChangePasswordProps = {

}

export const ChangePassword: React.FC<ChangePasswordProps> = () => {
  const theme = useTheme();
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const screenSizeDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const screeSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch=useDispatch();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: "",
  });

  const handleClose = () => {
    setOpenDrawer(false);
    setHasError(false);
    formRef?.current?.resetForm();
  }

  const onSuccess = () => {
    setIsLoading(false);
    setMessage({
      display: true,
      severity: "success",
      message: strings.common_save_message,
    });
  }
  
  let formRef: RefObject<Form | null | undefined> = useRef();

  const handleSave = async (e: MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      const { getFormData } = formRef.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData, isFormValid } = getFormData();
      const data = removeErrorFieldsFromValues(formData);
      setHasError(false);
      if (isFormValid) {
        if (data.newPassword !== data.confirmPassword) {
          setMessage({
            display: true,
            severity: "warning",
            message: strings.change_password_error_message,
          });
        } else {
          setIsLoading(true);
          const { status } = await axiosInstance.put(URLS.changePassword, data);
          if (status === 200) {
            onSuccess();
            handleClose();
            // logoutUser();
            dispatch(refreshToken() as any)
          }
        }
      } else {
        setIsLoading(false);
        setHasError(true);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.response) {
        setMessage({
          display: true,
          severity: "warning",
          message: error.response.data.message,
        });
      }
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  

  return (
    <>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
          autoHideDuration={2000}
        />
      )}
      <PrimaryButton
        onClick={() => setOpenDrawer(true)}
        variant={"outlined"}
        fullWidth
      >
        {strings.user_profile_change_password}
      </PrimaryButton>
      <DrawerMenu
        width={getDrawerWidth(screenSizeDownSm, screenSizeDownMd, screeSizeUpLg)}
        open={openDrawer}
        handleClose={handleClose}
        closeButton={true}
        title={strings.user_profile_change_password}>
        <ChangePasswordFormComponent hasError={hasError} formRef={formRef} isLoading={isLoading} handleSave={handleSave} theme={theme} />
      </DrawerMenu>
    </>
  );
};