export const URLS = {
  login: '/oauth/token',
  logout: '/authenticated/users/logout',
  forgot: '/api/users/forgot-password',
  verify: '/api/users/verify-code',
  resend: '/api/users/resend-code',
  reset: '/api/users/password',
  verifyCode: '/api/users/verify-code',
  resendCode: '/api/users/resend-code',
  resetPassword: '/authenticated/users/current-user/password',
  changePassword: '/authenticated/users/change-password',
  refresh_token: '/api/refresh-token',
  tarnslation: (language: string) => `/api/admin-translations/${language}`,

  // profile
  companyCredit: '/authenticated/user/company-credits',
  language: '/api/languages',
  countries: '/authenticated/countries',
  states: (id: number) => `/authenticated/countries/${id}/states`,
  cities: (id: number) => `/authenticated/states/${id}/cities`,
  userProfile: '/authenticated/users/profile',
  editUserProfile: '/authenticated/users/update-profile',

  // role
  role: '/authenticated/user/roles',
  rolePage: '/authenticated/roles/page',
  addRole: '/authenticated/roles',
  editRole: (id: string) => `/authenticated/roles/${id}`,
  deleteRole: (id: string) => `/authenticated/roles/${id}`,
  updatePriviliges: (id: string) => `authenticated/roles/${id}/privileges`,

  //product
  productList: '/authenticated/products/page',
  productAdd: '/authenticated/products',
  productListById: (id: any) => `/authenticated/products/${id}`,

  //variant
  variantList: (productID: any) => `/authenticated/products/${productID}/product-variants/page`,
  variantAdd: (productID: any) => `/authenticated/products/${productID}/product-variants`,
  variantListById: (productID: any, variantID: any) =>
    `/authenticated/products/${productID}/product-variants/${variantID}`,

  //coupons
  couponList: (id: any) => `authenticated/product-variants/${id}/coupons/page`,
  downloadTemplate: '/authenticated/coupons-template',
  deleteCoupon: (id: any) => `authenticated/coupons/${id}`,

  //orders
  orderList: '/authenticated/orders/page',
  downloadOrders: '/authenticated/orders/export/csv',

  // user management
  users: (flag: boolean) => `/authenticated/users/page?isCustomer=${flag}`,
  addUser: '/authenticated/users',
  getUserById: (id: string) => `/authenticated/users/${id}`,
  editUserById: (id: string) => `/authenticated/users/${id}`,
  deleteUserById: (id: string) => `/authenticated/users/${id}`,
  resetUser: (id: number) => `/authenticated/users/${id}/reset-password`,
  toggleUser: (id: number) => `/authenticated/users/${id}/toggle`,

  uploadProduct: '/authenticated/products/bulk-upload',
  uploadVariant: (productID: any) => `/authenticated/products/${productID}/product-variants/bulk-upload`,
  uploadCoupon: (productID: any, variantID: any) =>
    `/authenticated/products/${productID}/product-variants/${variantID}/coupons/bulk-upload`,

  downloadProductTemplate: '/authenticated/products/csv-template',
  downloadVariantTemplate: '/authenticated/product-variants/csv-template',
  downloadCouponsTemplate: '/authenticated/coupons/csv-template',
};
