import React, { FC, useCallback, useMemo, useState } from 'react';
import { Grid, Container, AlertColor } from '@mui/material';
import Notification from '../../../components/Notification';
import Modal from '../../../components/modal/Modal';
import axiosInstance from '../../../utils/axios';
import PageLoader from 'components/PageLoader';
import strings from 'common/Translation/Translate';
interface DeleteProps {
  openModal?: boolean;
  closeModal: any;
  deleteUrl: string;
  updateList: () => void;
}
const Delete: FC<DeleteProps> = ({ openModal, closeModal, deleteUrl, updateList }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.delete(deleteUrl);
      setMessage({
        display: true,
        message: strings.delete_success_message,
        severity: 'success',
      });
      setTimeout(() => {
        updateList();
        closeModal();
        setLoading(false);
      }, 1000);
    } catch (error: any) {
      if (error.response) {
        setMessage({
          display: true,
          severity: 'warning',
          message: error.response.data.message,
        });
      }
      setTimeout(() => {
        // closeModal();
        setLoading(false);
      }, 1000);
    }
  }, [deleteUrl, updateList, closeModal]);

  const deleteModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={closeModal}
        title={undefined}
        size={undefined}
        className={undefined}
        closeButton={false}
        contentTitle={strings.deleteText}
        contentText={strings.confirmDeletedText}
        backButtonText={strings.cancelText}
        backButtonFunc={closeModal}
        saveButtonText={strings.deleteText}
        saveButtonFunc={confirmDelete}
        loading={loading}
      />
    );
  }, [openModal, closeModal, loading, confirmDelete]);

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  return (
    <>
      {loading && <PageLoader />}
      <Container sx={{ p: '1rem' }} maxWidth={false}>
        {deleteModal}
        <Grid container spacing={2}>
          {message.display && (
            <Notification
              isOpen={message.display}
              message={message.message}
              severity={message.severity as AlertColor}
              closeNotification={closeNotification}
            />
          )}
        </Grid>
      </Container>
    </>
  );
};
export default Delete;
