import React, { Component, ReactElement } from 'react';
import InputField from '../InputField';
import { Grid, TextFieldProps } from '@mui/material';
import './form.scss';
import DropDownInput from '../DropDownInput';
import DateInput from '../DateInput/DateInput';
import dayjs from 'dayjs';
import RadioInput from '../RadioInput';
import MultiSelect from '../MultiSelect/MultiSelect';
import TextEditor from '../TextEditor/Index';
import Upload from '../Upload/Upload';
import Checkbox from '../Checkbox';
import Section from 'components/Section/Section';
import Autocomplete from '../Autocomplete/Autocomplete';

export interface FormValidators {
  check: any;
  message?: string;
  min?: number;
  max?: number;
}

export interface FormModel {
  field: any;
  label: string;
  placeholder: string;
  required: boolean;
  validators: FormValidators[];
  value: any;
  disabled?: boolean;
  styleClass?: string;
  responsive?: any;
  autoFocus?: any;
  variant?: any;
  size?: any;
  inputProps?: any;
  typeValue?: any;
  type?: any;
  options?: any;
  style?: any;
  className?: string;
  sx?: any;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: any;
  maxDate?: any;
  multiline?: boolean;
  rows?: number | string;
  minRows?: number | string;
  maxRows?: number | string;
  defaultValue?: any;
  dropdownData?: any;
  isMulti?: boolean;
  isClearable?: boolean;
  handledeleteImage?: (id: number) => void;
  defaultSource?: boolean;
  accept?: string;
  url?: any;
  infoText?: any;  
  width?: any;  
}

export interface FormDataModel {
  [key: string]: any;
}

interface FormProps {
  isFormUpdated?: boolean;
  values: any;
  model: FormModel[];
  strings?: any;
  testId?: string;
  onChange?: (
    field: string,
    value: string | boolean,
    formData: FormDataModel,
    deleted?: any,
    // isFormValid: boolean
  ) => void;
  onFocus?: (
    field: string,
    value: string | boolean,
    formData: FormDataModel,
    deleted?: any,
    // isFormValid: boolean
  ) => void;
  card?: any;
  hasError?: boolean;
  fieldError?: boolean;
  dropdownData?: any;
}

export default class Form extends Component<FormProps> {
  state: { formData: FormDataModel; isFormValid: boolean } = {
    formData: {},
    isFormValid: false,
  };

  componentDidMount() {
    this.prepareFormData();
  }

  componentDidUpdate(prevProps: Readonly<FormProps>) {
    const { values, strings } = this.props;
    if (
      this.props.isFormUpdated !== prevProps.isFormUpdated ||
      strings !== prevProps.strings ||
      (values && Object.keys(values).length > 0 && values !== prevProps.values)
    ) {
      this.prepareFormData();
    }
  }

  handleChange = (
    value: string | boolean,
    field: string,
    error?: { error: boolean; message: string },
    deleted?: any,
  ) => {
    const formData: any = this.state.formData;

    formData[field] = value;
    if (deleted?.deletedField === field) {
      formData[field + 'deleted'] = deleted?.DeletedFile;
    }
    formData[field + 'Error'] = error && error.error;
    this.setState({
      formData,
      isFormValid: this.validateForm(formData),
    });
    if (this.props.onChange) {
      // const isFormValid = this.validateForm(formData);
      this.props.onChange(field, value, formData, deleted);
    }
  };

  handleFocus = (value: string | boolean, field: string, deleted?: any) => {
    const formData: any = this.state.formData;

    if (this.props.onFocus) {
      // const isFormValid = this.validateForm(formData);
      this.props.onFocus(field, value, formData, deleted);
    }
  };

  validateForm = (formData: FormDataModel) => {
    const { model } = this.props;
    let isFormValid = true;
    model.forEach(item => {
      if (item.required || formData[item.field + 'Error']) {
        isFormValid = isFormValid && !formData[item.field + 'Error'];
      }
    });
    return isFormValid;
  };

  getFormData = () => {
    const { formData, isFormValid } = this.state;
    return { formData, isFormValid };
  };

  resetForm = () => {
    this.prepareFormData();
  };
  prepareFormDataHelper3 = (item: any, values: any, formData: FormDataModel) => {
    if (formData[item.field] || !item.required) {
      formData[item.field + 'Error'] =
        values[item.field] && values[item.field && item.field !== ''] && item.required ? true : false;
    } else {
      formData[item.field + 'Error'] =
        (values[item.field] || values[item.field] === 0) && values[item.field] !== '' && item.required ? false : true;
      // values[item.field] !== '' && item.required ? false : true;
    }
  };
  prepareFormDataHelper1 = (model: FormModel[], values: any, formData: FormDataModel) => {
    model.forEach(item => {
      formData[item.field] =
        (values[item.field] || values[item.field] === 0) && values[item.field] !== '' ? values[item.field] : '';
      this.prepareFormDataHelper3(item, values, formData);
    });
    this.setState({ formData, isFormValid: this.validateForm(formData) });
  };

  prepareFormDataHelper2 = (model: FormModel[], values: any, formData: FormDataModel) => {
    model.forEach(item => {
      formData[item.field] = values && values[item.field] ? values[item.field] : '';
      formData[item.field + 'Error'] = item.required;
    });

    this.setState({ formData, isFormValid: this.validateForm(formData) });
  };

  prepareFormData() {
    const { model, values } = this.props;
    const formData: FormDataModel = {};
    if (values && Object.keys(values).length !== 0) {
      this.prepareFormDataHelper1(model, values, formData);
    } else {
      this.prepareFormDataHelper2(model, values, formData);
    }
  }

  renderFormFields() {
    const { model, hasError, dropdownData } = this.props;
    const { formData } = this.state;
    const arrayOfFields: ReactElement[] = [];
    model.forEach((item, key) => {      
    const { required, label, placeholder, width } = item;
    const customLabel = width ? label : required ? `${label} *` : label
    const customPlaceholder = 
      placeholder === '' ? '' : required ? `${placeholder} *` : placeholder
      switch (item.type) {
        case 'text':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <InputField
                disabled={item.disabled || false}
                autoFocus={item.autoFocus || false}
                variant={item.variant}
                size={item.size}
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                multiline={item.multiline}
                rows={item.rows}
                minRows={item.minRows}
                maxRows={item.maxRows}
                inputValue={formData[item.field] || formData[item.field] === 0 ? (formData[item.field] as string) : ''}
                style={item.style}
                typeValue={item.typeValue || ''}
                label={customLabel || ''}
                fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                validators={item.validators}
                className={item.className}
                textChange={this.handleChange}
                sx={item.sx}
                infoText={item.infoText}
              />
            </Grid>,
          );
          break;
        case 'checkbox':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <Checkbox
                label={customLabel}
                field={item.field}
                value={formData[item.field] ? formData[item.field] : false}
                selected={formData[item.field] ? formData[item.field] : false}
                disabled={item.disabled}
                textChange={this.handleChange}
                sx={item.sx}
              />
            </Grid>,
          );
          break;
        case 'upload':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <Upload
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                accept={item.accept}
                multiline={item.multiline}
                handledeleteImage={item.handledeleteImage}
                inputValue={formData[item.field] || formData[item.field] === 0 ? (formData[item.field] as any) : []}
                style={item.style}
                typeValue={item.typeValue || ''}
                label={customLabel || ''}
                fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                validators={item.validators}
                textChange={this.handleChange}
                sx={item.sx}
              />
            </Grid>,
          );
          break;
        case 'drop-down':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <DropDownInput
                sx={item.sx}
                options={item.options}
                className={''}
                id={''}
                style={item.style}
                labelId={''}
                disabled={item.disabled || false}
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                inputValue={formData[item.field] || formData[item.field] === 0 ? (formData[item.field] as string) : ''}
                placeholder={customPlaceholder}
                label={customLabel || ''}
                fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                validators={item.validators}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                size={item.size}
              />
            </Grid>,
          );
          break;
        case 'select':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <MultiSelect
                options={dropdownData && dropdownData[item.field]}
                className={''}
                id={''}
                style={item.style}
                isClearable={item.isClearable}
                labelId={''}
                isMulti={item.isMulti}
                disabled={item.disabled || false}
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                inputValue={formData[item.field] || formData[item.field] === 0 ? (formData[item.field] as string) : ''}
                placeholder={customPlaceholder}
                label={customLabel || ''}
                fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                validators={item.validators}
                onChange={this.handleChange}
              />
            </Grid>,
          );
          break;
        case 'textEditor':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <TextEditor
                hasError={hasError || false}
                fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                validators={item.validators}
                label={customLabel}
                field={item.field}
                onChange={this.handleChange}
                value={formData[item.field] ? formData[item.field] : ''}
                defaultSource={item.defaultSource}
                disabled={item.disabled || false}
              />
            </Grid>,
          );
          break;
        case 'section':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <Section label={customLabel} field={item.field} />
            </Grid>,
          );
          break;
        case 'radio':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <RadioInput
                options={item.options}
                className={''}
                id={''}
                style={item.style}
                labelId={''}
                defaultValue={
                  formData[item.field] || formData[item.field] === 0 ? (formData[item.field] as string) : false
                }
                disabled={item.disabled || false}
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                inputValue={
                  formData[item.field] || formData[item.field] === 0 ? (formData[item.field] as string) : false
                }
                placeholder={customPlaceholder}
                label={customLabel || ''}
                fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                validators={item.validators}
                onChange={this.handleChange}
                size={item.size}
              />
            </Grid>,
          );
          break;
        case 'date':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <DateInput
                label={customLabel}
                validators={item.validators}
                textChange={this.handleChange}
                value={
                  formData[item.field] || formData[item.field] === 0
                    ? dayjs(new Date(formData[item.field] as string))
                    : null
                }
                field={item.field}
                size={item.size}
                onChange={e => e}
                disablePast={item.disablePast}
                disableFuture={item.disableFuture}
                placeholder={customPlaceholder}
                hasError={hasError || false}
                fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                minDate={item.minDate}
                maxDate={item.maxDate}
                disabled={item.disabled}
                renderInput={(params: TextFieldProps) => (
                  <InputField
                    size={item.size}
                    hasError={hasError || false}
                    inputValue={params.value as string}
                    fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                    {...params}
                  />
                )}
              />
            </Grid>,
          );
          break;
        case 'autocomplete':
          arrayOfFields.push(
            <Grid key={item.field} {...item.responsive} item className={'form-group ' + item.styleClass}>
              <Autocomplete
                sx={item.sx}
                url={item.url}
                id={''}
                disabled={item.disabled || false}
                hasError={hasError || false}
                field={item.field}
                value={formData[item.field] || formData[item.field] === 0 ? (formData[item.field] as string) : ''}
                placeholder={customPlaceholder}
                label={customLabel || ''}
                fieldError={item.field ? (formData[item.field + 'Error'] as boolean) : false}
                validators={item.validators}
                onChange={this.handleChange}
                size={item.size}
              />
            </Grid>,
          );
          break;
        default:
          break;
      }
    });

    return arrayOfFields;
  }

  render() {
    return <>{this.renderFormFields()}</>;
  }
}
