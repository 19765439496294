import { FormModel } from "components/Form";
import { Validators } from "utils/validators";

export const AddUserForm = (
  roleList: [],
): FormModel[] => {
  return [
    {
      label: 'First Name',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'firstName',
      validators: [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      responsive: { xs: 12, sm: 4 },
      required: true,
    },
    {
      label: 'Last Name',
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'lastName',
      validators: [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      responsive: { xs: 12, sm: 4 },
      required: true,
    },
    {
      label: 'Email Id',
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'email',
      validators: [
        { check: Validators.validateMandatoryField },
        { check: Validators.validateEmail },
        { check: Validators.validateString },
      ],
      responsive: { xs: 12, sm: 4 },
      required: true,
    },
    {
      label: 'Phone Number',
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'phone',
      validators: [{ check: Validators.validateMandatoryField }, { check: Validators.validateMobile }],
      responsive: { xs: 12, sm: 4 },
      required: true,
    },
    {
      label: 'Roles',
      value: '',
      size: 'medium',
      type: 'drop-down',
      placeholder: '',
      field: 'roles',
      validators: [{ check: Validators.validateMandatoryField }],
      responsive: { xs: 12, sm: 4 },
      required: true,
      options: roleList,
    },
  ];
};
