import { useState, FC, useEffect, ChangeEvent } from 'react';
import './style.scss';
import { validateInput } from '../../utils/validators/Validators';
import { TextFieldProps, Grid, FormHelperText, Typography, FormControl, IconButton, useTheme } from '@mui/material';
import { FormValidators } from '../Form';
import FileUpload from 'react-material-file-upload';
import { CloudDownloadOutlined } from '@mui/icons-material';
import { ToolTip } from '../Tooltip/ToolTip';
import strings from 'common/Translation/Translate';

interface OwnProps {
  inputValue?: any;
  field?: string;
  typeValue?: any;
  textChange?: (value: string, field: string, error?: { error: boolean; message: string }, deleted?: any) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: boolean | string;
  multiline?: boolean;
  deleteImages?: [];
  accept?: string | string[];
  sx?: any;
  handledeleteImage?: (id: number) => void;
}

const Upload: FC<OwnProps & TextFieldProps> = ({
  hasError,
  validators,
  textChange,
  field,
  // handledeleteImage,
  inputValue,
  fieldError,
  label,
  sx,
  multiline = false,
  accept,
}) => {
  const theme = useTheme();
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const [files, setFiles] = useState<File[]>(inputValue || []);
  const handleChange = (value: any, event: ChangeEvent<HTMLInputElement>) => {
    if (typeof event !== 'undefined') {
      Array.from(event.target.files || (value as FileList)).forEach((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
    }
    const inputError = validateInput(validators as FormValidators[], value);
    setError(inputError);

    if (textChange) {
      if (typeof event !== 'undefined') {
        if (multiline) {
          setFiles((files: any) => [...files, ...Array.from(value)]);
          textChange([...files, ...Array.from(value)] as any, field as string, inputError, event);
        } else {
          setFiles(value);
          textChange(value, field as string, inputError, event);
        }
      } else {
        let getIds = files.filter((object1: any) => {
          return !value.some((object2: any) => {
            return object1.id === object2.id;
          });
        });
        textChange(value, field as string, inputError, getIds || ([] as any));
      }
    }
  };
  useEffect(() => {
    const allFiles = inputValue?.filter((item: any, index: number) => inputValue?.indexOf(item) === index);
    setFiles(allFiles);
  }, [inputValue]);

  const customMessage = () => (!error && hasError && fieldError ? strings.requiredText : '');
  return (
    <Grid container>
      <Grid item xs={12} sx={{ mt: 0, mb: 2 }}>
        <Typography variant="subtitle1">{label}</Typography>
        <FileUpload
          maxSize={20000000}
          accept={accept}
          multiple={multiline}
          sx={{
            border: '2px dashed', minHeight: '194px',
            '.MuiFormHelperText-root': {
              'color': 'red'
            },
            ...sx
          }}
          value={files as any}
          onChange={handleChange as any}
          buttonProps={{ sx: { color: '#ffffff' } }}
          buttonText={strings.upload_button_text}
        />
        <FormControl error={!!((error && error.error) || (!error && hasError && fieldError))}>
          <FormHelperText>{error && error.error ? error.message : customMessage()}</FormHelperText>
        </FormControl>
      </Grid>
      {files && files?.length > 0 && (
        <Grid gap={2} item container sx={{ mt: 0, mb: 2 }}>
          {files?.map((fileData: any, index: number) => (
            <ToolTip key={fileData.name} title={fileData.preview}>
              <Grid
                item
                xs={12}
                sx={{
                  p: 2,
                  position: 'relative',
                  alignItems: 'center',
                  border: `1px dashed ${theme.palette.primary.main}`,
                  justifyContent: 'center',
                  borderRadius: '2px',
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
                  my: 1,
                  ml: 0,
                  width: 'calc(100% - 15px)',
                }}>
                <IconButton aria-label="delete" color={'primary'} sx={{ position: 'absolute', bottom: 0, right: 10 }}>
                  <a
                    rel="noreferrer"
                    href={fileData.preview}
                    target="_blank"
                    className="download"
                    download={fileData.name}>
                    <CloudDownloadOutlined sx={{ color: theme.palette.primary.main }} />
                  </a>
                </IconButton>

                {/* <DescriptionIcon sx={{ fontSize: "50px" }} /> */}
                <Typography
                  sx={{
                    fontSize: '12px',
                    width: '85%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant="body1">
                  {fileData.name}
                </Typography>
                {/* <IconButton
                                    sx={{ position: 'absolute', top: 0, right: 10, fontSize: '10px' }}
                                    aria-label="delete"
                                    color={"error"}
                                    // disabled={deleteImages && deleteImages.indexOf(fileData.id) > -1}
                                    onClick={() => handledeleteImage && handledeleteImage(fileData)}
                                >
                                    <DeleteIcon />
                                </IconButton> */}
              </Grid>
            </ToolTip>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
export default Upload;
