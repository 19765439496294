import { Validators } from '../../../utils/validators';
import { FormModel } from '../../../components/Form';

export const ResetForm = (strings: any, firstTimeLogin: boolean): FormModel[] => {
  return [
    {
      autoFocus: true,
      label: firstTimeLogin ? `Old Password *` : `New Password *`,
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'password',
      variant: 'outlined',
      placeholder: '',
      field: firstTimeLogin ? 'oldPassword' : 'password',
      validators: [{ check: Validators.validateMandatoryField }, { check: Validators.validateString }],
      sx: { mb: 2 },
      responsive: { xs: 12 },
      required: true,
    },
    {
      label: firstTimeLogin ? `New Password *` : `Confirm Password *`,
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'password',
      variant: 'outlined',
      placeholder: '',
      field: firstTimeLogin ? 'newPassword' : 'confirmPassword',
      validators: [
        { check: Validators.validateMandatoryField },
        { check: Validators.validateString },
        { check: Validators.validatePassword },
      ],
      responsive: { xs: 12 },
      required: true,
    },
  ];
};
