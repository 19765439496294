import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import {
  Avatar,
  Box,
  Container,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBarProps as MuiAppBarProps,
  Popover,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import gifteez_logo from '../../assets/images/logo-no-background.png';
import strings from '../../common/Translation/Translate';
import { ToolTip } from '../../components/Tooltip/ToolTip';
import Logo from 'assets/images/Logo';
import axiosInstance from '../../utils/axios';
import { identifiers } from '../../utils/constants/identifiers';
import { routes } from '../../utils/constants/routes';
import { URLS } from '../../utils/constants/urls';
import { setAuthentication } from '../../utils/redux';
import { Privilege } from '../../utils/redux/reducer/authentication-slice';
import { clearStorage, fetchFromStorage } from '../../utils/storage';
import config from './config';
import Scrollbar from 'react-scrollbars-custom';

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => {
  if (useMediaQuery(theme.breakpoints.up('md'))) {
    return {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    };
  } else {
    return {};
  }
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme, open }) => {
  if (useMediaQuery(theme.breakpoints.up('md'))) {
    return {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    };
  } else {
    return {};
  }
});

const AuthenticatedLayout: FC<{ Component: FC }> = ({ Component }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const openPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // handleProfile();
  };

  const openPop = Boolean(anchorEl);

  const theme = useTheme();
  const screenSizeUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const privilegeData = useSelector(Privilege);
  const initOrg = fetchFromStorage(identifiers.organization) as any;
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [submenu, setSubmenu] = useState<boolean>(false);
  const [showSideBar, setShowSideBar] = useState<boolean>(screenSizeUpMd);
  const [key, setKey] = useState<string>();
  const handleProfile = () => {
    Navigate(routes.profile);
  };
  const handleLogout = async () => {
    const body: { refreshToken: string | null } = {
      refreshToken: fetchFromStorage(identifiers.refresh_token),
    };
    try {
      const { status }: { status: number } = await axiosInstance.post(URLS.logout, body);
      if (status === 200) {
        clearStorage();
        dispatch(setAuthentication(null));
      }
    } catch (e) {
      clearStorage();
      dispatch(setAuthentication(null));
    }
  };

  const getUser = useCallback(async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.userProfile);
      if (status === 200) {
        setUserData(data);
      }
    } catch (e) {
      //console.log(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const menu = useMemo(() => {
    const handleExpand = (key: string) => {
      setKey(key);
      setSubmenu(false);
      setOpen(false);
    };

    const custom = (menuItem: any, state: any, val: string) =>
      !state && menuItem.key === val ? <KeyboardArrowRightIcon /> : <ExpandMore />;
    return (
      <List>
        {config(privilegeData, strings)
          .filter((item: any) => item)
          .map((menuItem: any, index: number) => (
            <div key={menuItem.route}>
              <ListItem
                key={menuItem.key}
                disablePadding
                sx={{
                  backgroundColor:
                    `/${location.pathname.split('/')[1]}` === menuItem.route
                      ? `${theme.palette.secondary.main}`
                      : 'inherit',
                  '&.MuiListItem-root, .MuiListItemIcon-root, .MuiListItemText-root': {
                    color:
                      `/${location.pathname.split('/')[1]}` === menuItem.route
                        ? `${theme.palette.secondary.contrastText} !important`
                        : 'inherit',
                  },
                }}>
                <ListItemButton
                  onClick={() => {
                    handleExpand(menuItem.key);
                    if (!screenSizeUpMd && !menuItem?.children) setShowSideBar(!showSideBar);
                    Navigate(menuItem.route);
                  }}
                  sx={{
                    color:
                      `/${location.pathname.split('/')[1]}` === menuItem.route
                        ? theme.palette.secondary.contrastText
                        : '#ffffff',
                  }}>
                  <ListItemIcon
                    sx={{
                      color:
                        `/${location.pathname.split('/')[1]}` === menuItem.route
                          ? theme.palette.secondary.contrastText
                          : '#ffffff',
                      minWidth: '30px',
                      fontSize: '10px',
                    }}>
                    <menuItem.icon
                      sx={{
                        fontSize: '20px',
                        color:
                          `/${location.pathname.split('/')[1]}` === menuItem.route
                            ? `${theme.palette.secondary.contrastText}`
                            : theme.palette.primary.contrastText,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={menuItem.name}
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: 500,
                        color:
                          `/${location.pathname.split('/')[1]}` === menuItem.route
                            ? `${theme.palette.secondary.contrastText}`
                            : theme.palette.primary.contrastText,
                      },
                      fontSize: '0.9rem',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </div>
          ))}
      </List>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilegeData, open, submenu, location.pathname, key, Navigate, showSideBar]);
  return (
    <Container sx={{ display: 'flex', height: '100%' }} maxWidth={false}>
      <CssBaseline />
      <Drawer
        onClose={() => setShowSideBar(!showSideBar)}
        open={initOrg === '*' ? false : showSideBar}
        variant={screenSizeUpMd && initOrg !== '*' ? 'persistent' : 'temporary'}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          height: '100%',
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            height: '100%',
            boxSizing: 'border-box',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '.MuiCollapse-root': { backgroundColor: 'rgba(0,0,0,0.3)', color: theme.palette.primary.contrastText },
          }}
          height="100%">

          {useMediaQuery(theme.breakpoints.down('md')) &&
            <Box display={'flex'} alignItems={'center'} pt={1}>
              <IconButton
                sx={{ color: theme.palette.secondary.main }}
                onClick={() => setShowSideBar(!showSideBar)}>
                <MenuOutlinedIcon fontSize="large" />
              </IconButton>
              <Logo />
            </Box>
          }
          {screenSizeUpMd &&
            <Box display={'flex'} alignItems={'center'} gap={1} py={1} justifyContent={'center'}>
              {/* <CardGiftcardIcon fontSize='small' sx={{}} />
            <Typography>{strings.rewardsText}</Typography> */}
              <Logo />
            </Box>}
          <Scrollbar style={{ width: '100%', height: '93vh' }}>{menu}</Scrollbar>
        </Box>
      </Drawer>
      <Main open={showSideBar} sx={{ width: screenSizeUpMd ? `calc(100% - ${drawerWidth}px)` : '100%', px: 0 }}>
        {/* <CustomTheme open={openDrawer} setOpen={setOpenDrawer} /> */}
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            boxShadow: 'none',
            color: theme.palette.secondary.contrastText,
          }}>
          <Toolbar>
            <Box
              ml={(screenSizeUpMd && (showSideBar ? '235px' : '0')) || '0'}
              component="div"
              sx={{
                flexGrow: 1,
                color: theme.palette.secondary.contrastText,
                display: 'flex',
                gap: '10px',
                transition: '0.2s',
              }}>
              <IconButton
                sx={{ background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}
                onClick={() => setShowSideBar(!showSideBar)}>
                <MenuOutlinedIcon fontSize="large" />
              </IconButton>
            </Box>
            <ToolTip title={screenSizeUpMd ? '' : `${userData.firstName} ${userData.lastName}`}>
              <Avatar
                sx={{
                  marginLeft: 2,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  width: 30,
                  height: 30,
                }}
                onClick={(e: any) => (!screenSizeUpMd ? openPopOver(e) : handleProfile())}
                className={'cursor-pointer'}>
                <Typography variant="h6">{userData.firstName?.slice(0, 1)}</Typography>
              </Avatar>
            </ToolTip>
            <Popover
              open={openPop}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Grid container flexDirection={'column'} gap={1} py={2} pr={2}>
                <Grid item>
                  <Grid container alignItems={'center'}>
                    <Grid item>
                      <ToolTip title={screenSizeUpMd ? '' : `${userData.firstName} ${userData.lastName}`}>
                        <Avatar
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            width: 25,
                            height: 25,
                            ml: 1,
                          }}
                          className={'cursor-pointer'}>
                          <Typography variant="h6">{userData.firstName?.slice(0, 1)}</Typography>
                        </Avatar>
                      </ToolTip>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        onClick={() => {
                          handleClose();
                          handleProfile();
                        }}
                        className={'cursor-pointer'}
                        sx={{ mx: 2, fontWeight: 600 }}>
                        {strings.updateProfile_title_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <LogoutIcon
                      sx={{
                        color: theme.palette.primary.main,
                        ml: 1,
                      }}
                    />
                    <Typography
                      variant="body2"
                      onClick={handleLogout}
                      className={'cursor-pointer'}
                      sx={{ mx: 2, fontWeight: 600 }}>
                      {strings.logoutText}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Popover>
            {screenSizeUpMd && (
              <>
                <Typography
                  variant="body2"
                  onClick={handleProfile}
                  className={'cursor-pointer'}
                  sx={{ mx: 2, fontWeight: 600 }}>
                  {userData.firstName} {userData.lastName}
                </Typography>
                <ToolTip title={strings.logoutText}>
                  <IconButton
                    sx={{ color: theme.palette.secondary.contrastText }}
                    onClick={handleLogout}
                    aria-label="delete">
                    <LogoutIcon />
                  </IconButton>
                </ToolTip>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Component />
      </Main>
    </Container>
  );
};

export default AuthenticatedLayout;
