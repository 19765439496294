/* eslint-disable eqeqeq */
import strings from 'common/Translation/Translate';
import { FormValidators } from '../../components/Form';

const regex = {
  number: new RegExp(/^[0-9]*$/),
  password: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d])(?!.*\s).{8,15}$/),
  pinCode: new RegExp(/^\d{4,9}$/),
  email: new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}])|(([a-zA-Z\\-\\d]+\\.)+[a-zA-Z]{2,}))$',
  ),
  mobile: new RegExp(/^\+?(\d|-|\s){6,50}$/),
  url: new RegExp(
    '^(https?://)?(www\\.)?([-a-z\\d]{1,63}\\.)*?[a-z\\d][-a-z\\d]{0,61}[a-z\\d]\\.[a-z]{2,6}(/[-\\w@+.~#?&/=%]*)?$',
  ),
  // mobile: new RegExp(/^\d{10}$/),
  // username: new RegExp(/^[a-zA-Z\d](?:[a-zA-Z\d_-]*[a-zA-Z\d])?$/),
  // decimalNumber: new RegExp('^[-+]?[0-9]+\\.[0-9]+$'),
  // floatNumber: new RegExp(/^\d+(\.\d{1,6})?$/),
  // referenceNumber: new RegExp('^[a-zA-Z\\d]+$'),
  // price: new RegExp(/^\d+(\.\d{1,2})?$/),
  // amount: new RegExp(/^[+]?(\d+(?:[.]\d*)?|\.\d+)$/),
  // amountRefunded: new RegExp(/^d{10}$/),
  // Time: new RegExp('^(\\d|0\\d|1\\d|2[0-3]):[0-5]\\d$'),
  // name: new RegExp(/^[a-zA-Z. ]+$/),
  // panCard: /([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
  // GST: new RegExp(/^(0[1-9]|[1-2]\d|3[0-5])([a-zA-Z]{5}\d{4}[a-zA-Z][1-9a-zA-Z][zZ][\da-zA-Z])+$/),
};

export class Validators {
  static validateMandatoryField(input: string, customMessage: string) {
    if (!input || !input.toString().trim().length || input === '') {
      return { error: true, message: customMessage || strings.requiredText };
    }
    return false;
  }

  static validateString(input: string, customMessage: string, min: number, max: number) {
    let minLength = min || 0;
    let maxLength = max || 200;
    const inputLength = input.trim() ? input.trim().toString().length : 0;
    if (inputLength < minLength) {
      return { error: true, message: customMessage || strings.insufficient_length(minLength) };
    }
    if (inputLength !== 0 && inputLength > maxLength) {
      return { error: true, message: customMessage || strings.exceeded_character_limit(maxLength) };
    }
    return false;
  }

  static validateInt(input: string, customMessage: string, min: number, max: number) {
    let minNumber = min || 0;
    let maxNumber = max || 100000000; // 1 Crore

    // const inputLength = input ? input.toString().length : 0;
    const inputLength = parseInt(input);

    if (inputLength >= 0) {
      const result = regex.number.test(input);
      if (!result) {
        return { error: true, message: customMessage || strings.common_number_validation_error };
      }
    }
    if (parseInt(input) < minNumber) {
      return { error: true, message: customMessage || strings.common_min_value_error(minNumber) };
    }
    if (parseInt(input) > maxNumber) {
      return { error: true, message: customMessage || strings.common_max_value_error(maxNumber) };
    }
    return false;
  }

  static validateFloat(input: string, customMessage: string, min: number, max: number) {
    let minNumber = min || 0;
    let maxNumber = max || 100000000; // 1 Crore

    if (parseFloat(input) < minNumber) {
      return { error: true, message: customMessage || strings.common_min_value_error(minNumber) };
    }
    if (parseFloat(input) > maxNumber) {
      return { error: true, message: customMessage || strings.common_max_value_error(maxNumber) };
    }
    return false;
  }

  static validateEmail(value: string, message: string) {
    if (value) {
      const result = regex.email.test(value);
      if (!result) {
        return { error: true, message: message || strings.email_invalid_error };
      }
    }
  }

  static validateEmailList(value: string, message: string) {
    if (value) {
      const result = value
        .split(',')
        .filter(email => email != '')
        .map(email => {
          if (email != '') {
            return Validators.validateEmail(email.trim(), strings.invalid_email_message(email));
          } else return { error: true, message };
        });

      let res;
      result.forEach(element => {
        if (element?.error) {
          res = element.message;
        }
      });
      if (res) return { error: true, message: res };
    }
  }

  private static validatePattern(input: string, customMessage: string, regExp: RegExp) {
    if (input) {
      const result = regExp.test(input);
      if (!result) {
        return { error: true, message: customMessage || strings.common_validation_error };
      }
      return false;
    }
  }

  static validateMobile(value: string, message: string) {
    return Validators.validatePattern(value, message || strings.phone_no_invalid_error, regex.mobile);
  }

  static validatePinCode(input: string, message: string) {
    return Validators.validatePattern(input, message || strings.valid_pinCode_error, regex.pinCode);
  }

  static validatePassword(value: string, message: string) {
    return Validators.validatePattern(value, message || strings.password_validation_message, regex.password);
  }

  static ValidateUrl(value: string, message: string) {
    return Validators.validatePattern(value.toLowerCase(), message || strings.common_urlError, regex.url);
  }

  // static min(value: string, message: string, min: number, max: number) {
  //   if (parseFloat(value) < min) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }

  // static max(value: string, message: string, num: number) {
  //   if (parseFloat(value) > num) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }

  // static maxlength(value: string, message: string, num: number) {
  //   const result = value.length > num ? false : true;
  //   if (!result) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }

  // static maxCharLimit(value: string, message: string, num: number) {
  //   const length = value.trim() ? value.toString().length : 0;
  //   if (length !== 0 && length > num) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }

  // static minCharLimit(value: string, message: string, num: number) {
  //   const length = value.trim() ? value.toString().length : 0;
  //   if (length < num) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }

  // static minDate(value: string, message: string, date: Date) {
  //   if (new Date(value).getTime() < new Date(date).getTime()) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }

  // static maxDate(value: string, message: string, date: Date) {
  //   if (new Date(value).getTime() > new Date(date).getTime()) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }

  // static equalToZero(value: string, message: string) {
  //   if (parseInt(value) === 0) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }

  // static max100(value: string, message: string) {
  //   if (parseFloat(value) > 100) {
  //     return { error: true, message };
  //   }
  // }

  // static minCount(value: string, message: string, num: number) {
  //   const length = value ? value.length : 0;
  //   if (length < num) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }

  // static panCard(value: string, message: string) {
  //   return Validators.validatePattern(value.toUpperCase(), message || strings.common_urlError, regex.panCard);
  // }

  // private static validateNumberPattern(input: string, customMessage: string, regExp: RegExp) {
  //   const inputLength = input ? input.toString().length : 0;
  //   if (inputLength >= 0) {
  //     const result = regExp.test(input);
  //     if (!result) {
  //       return { error: true, message: customMessage || strings.common_validation_error };
  //     }
  //   }
  //   return false;
  // }

  // static number(input: string, message: string) {
  //   return Validators.validateNumberPattern(input, message || strings.required_valid_no, regex.number);
  // }

  // static floatNumber(input: string, message: string) {
  //   return Validators.validateNumberPattern(input, message || strings.required_valid_no, regex.floatNumber);
  // }

  // static decimal(input: string, message: string) {
  //   const length = input ? input.toString().length : 0;
  //   if (length > 0) {
  //     const result = regex.decimalNumber.test(input) || regex.number.test(input);
  //     if (!result) {
  //       return { error: true, message };
  //     }
  //   }
  //   return false;
  // }

  // static price(value: string, message: string) {
  //   const length = value ? value.toString().length : 0;
  //   if (length > 0 || parseInt(value) <= 0) {
  //     if (parseInt(value) === 0) return { error: true, message };
  //     else {
  //       const result = regex.price.test(value);
  //       if (!result) {
  //         return { error: true, message };
  //       }
  //     }
  //   }
  //   return false;
  // }

  // static amountRefunded(value: string, message: string) {
  //   const length = value ? value.toString().length : 0;
  //   if (length > 10) {
  //     const result = regex.amountRefunded.test(value);
  //     if (!result) {
  //       return { error: true, message };
  //     }
  //   }
  //   return false;
  // }

  // static referenceNumber(value: string, message: string) {
  //   const length = value ? value.toString().length : 0;
  //   if (length > 100) {
  //     const result = regex.referenceNumber.test(value);
  //     if (!result) {
  //       return { error: true, message };
  //     }
  //   }
  //   return false;
  // }

  // static noRoom(value: string, message: string) {
  //   const length = value ? value.toString().length : 0;
  //   if (length > 4) {
  //     return { error: true, message };
  //   }
  //   return false;
  // }
}

export const validateInput = (validators: FormValidators[], value: string) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].check(value, validators[i].message, validators[i]?.min, validators[i]?.max);
      if (error) {
        return error;
      }
    }
  }
  return false;
};
