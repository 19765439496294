import React, { FC, MouseEvent, RefObject, useRef, useState } from 'react';
import './ForgotPassword.scss';
import {
  AlertColor,
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Form, { FormDataModel } from '../../../components/Form';
import Notification from '../../../components/Notification/Notification';
import { useNavigate } from 'react-router-dom';
import { ForgotForm } from './ForgotForm';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import { removeErrorFieldsFromValues } from '../../../utils/validators';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../utils/axios';
import { URLS } from '../../../utils/constants/urls';
import Captcha from '../../../components/Captcha/Captcha';
import strings from '../../../common/Translation/Translate';
import LogoPrimary from 'assets/images/LogoPrimary';

const ForgotPassword: FC = () => {
  const Navigate = useNavigate();
  const theme = useTheme();
  let forgotForm: RefObject<Form | null | undefined> = useRef();
  const screenSizeUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const screenSizeDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: '',
  });

  const handleForgot = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { getFormData } = forgotForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const body = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        setLoading(true);
        const { status } = await axiosInstance.post(URLS.forgot, {
          ...body,
        });
        if (status === 200) {
          setLoading(false);
          Navigate(`/reset-password?email=${body.email}`);
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setActionMessage(true, 'warning', error.response.data.message);
      }
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const setActionMessage = (
    display: boolean = false,
    severity: AlertColor | null = 'warning',
    message: string = '',
  ) => {
    setMessage({
      display,
      severity,
      message,
    });
  };
  return (
    <Container sx={screenSizeUpLg ? {} : { px: 0 }} className={'forgot-container'} maxWidth={false}>
      <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh)' }}>
        {message.display && (
          <Notification
            isOpen={message.display}
            message={message.message}
            severity={message.severity as AlertColor}
            closeNotification={closeNotification}
          />
        )}
        <Grid item xs={12} sm={8} lg={4}>
          <Box textAlign="center" mb={3}>
            <LogoPrimary />
          </Box>
          <Card sx={{ p: screenSizeUpLg ? 2 : 1, mx: screenSizeDownSm ? 2 : 0 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {strings.forgotPassword}
            </Typography>
            <form onSubmit={handleForgot as any}>
              <Form hasError={hasError} ref={forgotForm as RefObject<Form>} model={ForgotForm(strings)} values={{}} />
              <PrimaryButton disabled={loading} sx={{ mt: 1, p: 1.5 }} onClick={handleForgot}>
                {loading && <CircularProgress color="inherit" sx={{ mr: 1 }} size={20} />}
                {strings.forgotPassword}
              </PrimaryButton>
            </form>
            <Link to={'/login'} className="other-links">
              <Typography variant="body1" sx={{ mb: 1, mt: 2 }} align={'center'}>
                Have an account ? Login
              </Typography>
            </Link>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;
