import { createSlice } from '@reduxjs/toolkit';
import { clearStorage, fetchFromStorage, removeFromStorage, saveToStorage } from '../../storage';
import { identifiers } from '../../constants/identifiers';
import { URLS } from '../../constants/urls';
import axiosInstance from '../../axios';
import strings from '../../../common/Translation/Translate';

const fromStorage = fetchFromStorage(identifiers.access_token);
const link = fetchFromStorage(identifiers.preview);
interface AuthenticationState {
  user: any;
  authenticated: boolean;
  privilege: any;
  priviewLink: string | null;
  selectedlanguage: any;
}
// const defaultLang = fetchFromStorage(identifiers.languages) && JSON.parse(fetchFromStorage(identifiers.languages) as any)[0];
const authenticationSlice = createSlice({
  name: 'authenticationSlice',
  initialState: {
    authenticated: fromStorage || false,    
    user: null,
    priviewLink: link || '',
    selectedlanguage: {value: 'en_IN'},
    privilege: fetchFromStorage(identifiers.privilegeList),
  },
  reducers: {
    setAuthentication(state, action) {
      if (action.payload) {
        // const decoded = jwt_decode(action.payload.token) as any;
        // //console.log(decoded);
        saveToStorage(identifiers.access_token, action.payload.access_token);
        saveToStorage(identifiers.privilegeList, action.payload.privileges);
        saveToStorage(identifiers.refresh_token, action.payload.refresh_token);
        saveToStorage(identifiers.preview, action.payload.previewLink);
        state.priviewLink = action.payload.priviewLink;
        state.authenticated = true;
        state.privilege = action.payload.privileges;

      } else {
        removeFromStorage(identifiers.access_token);
        state.authenticated = false;
      }
    },
    logout(state) {
      clearStorage('all');
      state.authenticated = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});
export const refreshToken = (refreshtoken?: string) => async (dispatch: any) => {
  const body = new URLSearchParams();
  const token = fetchFromStorage(identifiers.refresh_token);

  body.append('refresh_token', refreshtoken || token || '');
  body.append('scope', 'webclient');
  body.append('grant_type', 'refresh_token');
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    auth: {
      username: process.env.REACT_APP_CLIENT,
      password: process.env.REACT_APP_SECRET,
    },
  };

  return axiosInstance.post(URLS.login, body, config as any).then(({ data, status }) => {
    try {
      if (status === 200) {
        removeFromStorage(identifiers.token);
        dispatch(setAuthentication(data));
      }
    } catch (e: any) {
      //console.log(e);
    }
  });
};

export const handleLogout = (refreshToken: string) => async (dispatch: any) => {
  const body: { refreshToken: string | null } = {
    refreshToken: refreshToken || fetchFromStorage(identifiers.refresh_token),
  };
  try {
    await axiosInstance.post(URLS.logout, body);
    clearStorage();
    dispatch(setAuthentication(null));
  } catch (e: any) {
    clearStorage();
  }
};


export default authenticationSlice.reducer;

export const {
  setAuthentication,
  logout,
  setUser,
} = authenticationSlice.actions;

export const User = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.user;
export const Privilege = (state: { authenticationSlice: AuthenticationState }) => state.authenticationSlice.privilege;
export const Authenticated = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.authenticated;
export const priviewLink = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.priviewLink;
export const defaultLanguage = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.selectedlanguage;
