import { FC } from 'react';
import Drawer from '@mui/material/Drawer';
import { Divider, Grid, IconButton, Typography, useTheme } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

type DrawerProps = {
  open: boolean;
  handleClose: () => void;
  children: any;
  closeButton?: boolean;
  width?: number | string;
  title: any;
  dropBack?: boolean;
};
const DrawerMenu: FC<DrawerProps> = ({
  open,
  handleClose,
  children,
  closeButton = false,
  dropBack = true,
  width = 500,
  title,
}) => {
  const theme = useTheme();
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: width
        },
      }}
      anchor="right"
      open={open}
      onClose={dropBack ? handleClose : () => {}}>
      <Grid container className="content">
        <Grid
          sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}
          container
          item
          alignItems="center"
          p={2}>
          
          <Grid item xs={10}>
            {title && (
              <Typography fontWeight="500" fontSize="20px">
                {' '}
                {title}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2} textAlign={'right'}>
            {closeButton && (
              <IconButton size="small" color="default" className="deleteButton" onClick={handleClose} aria-label="edit">
                <HighlightOffOutlinedIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2 }} />
        <Grid item container sx={{ my: 0, px: 3 }}>
          {children}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default DrawerMenu;
