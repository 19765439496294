import { FormModel } from "components/Form";
import { Validators } from "utils/validators";


export const CouponFilterForm = (strings?: any, width = 4): FormModel[] => {
  return [
    {
      label: 'Coupon Code',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'couponCode',
      validators:[],
      responsive: { xs: 12 },
      required: false,
      width: width
    },
    {
      label: 'status',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'string',
      variant: 'outlined',
      placeholder: '',
      field: 'status',
      validators: [] ,
      responsive: { xs: 12 },
      required: false,
      width: width
    },
  ];
};
