import { Avatar, Container, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { identifiers } from 'utils/constants/identifiers';
import { fetchFromStorage } from 'utils/storage';
import strings from '../../../common/Translation/Translate';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import axiosInstance from '../../../utils/axios';
import { routes } from '../../../utils/constants/routes';
import { URLS } from '../../../utils/constants/urls';
import Navigation from '../../../utils/hooks/Navigation';
import { ChangePassword } from './ChangePassword';
import './UserProfile.scss';

const UserProfile: FC = () => {
  const theme = useTheme();
  const [userData, setUserData] = useState<any>();
  const Navigate = useNavigate();
  const initOrg = fetchFromStorage(identifiers.organization) as any;
  const getUser = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.userProfile);
      setUserData(data);
    } catch (error: any) {
      //console.log(error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  return (
    <Container maxWidth={false}>
      {!!userData && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: 24, fontWeight: 500 }}>User Profile</Typography>
            </Grid>
            {initOrg !== '*' && <Grid item textAlign="right" xs={2}>
              <Navigation url="/" />
            </Grid>}
          </Grid>
          <Divider sx={{ mt: 2 }} />
          <Paper sx={{ p: 1 }}>
            <Grid container spacing={1} sx={{ my: 1, ml: 2 }}>
              <Grid item xs={12} sm={2} lg={1} sx={{ mr: 2 }}>
                <Avatar
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    width: 80,
                    height: 80,
                  }}>
                  <Typography variant="h3">{userData.firstName?.slice(0, 1)}</Typography>
                </Avatar>
              </Grid>
              <Grid item sx={{ overflowWrap: 'anywhere' }} pr={3}>
                <Typography className="profile-name">
                  {userData.firstName} {userData.lastName}
                </Typography>
                <Typography className="profile-info">{userData.email}</Typography>
                <Typography className="profile-info">{userData.phone}</Typography>

                <Grid container mt={2} spacing={1} whiteSpace={'nowrap'}>
                  <Grid item xs={12} sm={6}>
                    <PrimaryButton onClick={() => Navigate(routes.editProfile + '/' + userData.id)} fullWidth>
                      {strings.user_profile_edit_profile}
                    </PrimaryButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ChangePassword />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </Container>
  );
};

export default UserProfile;
