import { FormModel } from "components/Form";
import { Validators } from "utils/validators";

export const OrdersFilterForm = (strings?: any, width = 4): FormModel[] => {
  return [
    {
      label: 'Name',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'name',
      validators: [],
      responsive: { xs: 12 },
      required: false,
      width: width
    },
    {
      label: 'Email',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'string',
      variant: 'outlined',
      placeholder: '',
      field: 'email',
      validators: [{ check: Validators.validateEmail }],
      responsive: { xs: 12 },
      required: false,
      width: width
    },
    {
      label: 'Phone',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'string',
      variant: 'outlined',
      placeholder: '',
      field: 'phone',
      validators: [{ check: Validators.validateMobile }],
      responsive: { xs: 12 },
      required: false,
      width: width
    },
    {
      label: 'OrderId',
      value: '',
      size: 'medium',
      autoFocus: false,
      type: 'text',
      typeValue: 'string',
      variant: 'outlined',
      placeholder: '',
      field: 'orderId',
      validators: [],
      responsive: { xs: 12 },
      required: false,
      width: width
    },
    {
      label: 'Coupon Code',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'couponCode',
      validators: [],
      responsive: { xs: 12 },
      required: false,
      width: width
    },    
    {
      label: 'Variant code',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'variantCode',
      validators: [],
      responsive: { xs: 12 },
      required: false,
      width: width
    },
    {
      label: 'Product Code',
      value: '',
      size: 'medium',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'productCode',
      validators: [],
      responsive: { xs: 12 },
      required: false,
      width: width
    },    
  ];
};
