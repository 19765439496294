import React, { FC } from 'react';

import './checkboxStyle.css';
import { CheckboxProps, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

interface OwnProps {
  label?: string;
  selected?: any;
  field?: string;
  textChange?: (value: boolean, field: string, error?: { error: boolean; message: string }) => void;
}

const CheckBox: FC<OwnProps & CheckboxProps> = ({ label, selected, disabled, textChange, field, sx }) => {
  const handleChange = (event: { target: { checked: boolean } }) => {
    const value = event.target.checked;
    if (textChange) textChange(value, field as string, { error: false, message: '' });
  };

  return (
    <FormGroup sx={sx}>
      <FormControlLabel
        control={<Checkbox data-testid={'test'} disabled={disabled} checked={selected} onChange={handleChange} />}
        label={label}
      />
    </FormGroup>
  );
};

export default CheckBox;
