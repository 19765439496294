import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import store from './utils/redux';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StyledEngineProvider injectFirst>
    <CssBaseline />
    <Provider store={store}>
      <Router basename="/">
        <App />
      </Router>
    </Provider>
  </StyledEngineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
